import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { HttpErrorResponse } from '@angular/common/http';
import { Constants } from '../shared/constants';
import { of } from 'rxjs';

const httpOptions = { headers: new HttpHeaders({'Content-Type':  'application/json'}) };

@Injectable()
export class TestService {

  constructor(private http: HttpClient) { }

  testAlwaysTrue(): Observable<any> {

    console.log(Constants.TEST_AUTH_ALWAYSTRUE_POLICY_URL);

    let authAlwaysTrue = environment.apiBaseUrl.concat(Constants.TEST_AUTH_ALWAYSTRUE_POLICY_URL)

    console.log(authAlwaysTrue);

    return this.http.get(authAlwaysTrue).map(
        data => {

          console.log(`http get ${authAlwaysTrue} - returned ok`);

          return data;
        }).catch(e => 
          { 
            console.log(`http get ${authAlwaysTrue} - returned error`);

            console.log(e); return Observable.of(e); 
          })
    };

  testContractorPolicy(): Observable<any> {

      console.log(Constants.TEST_AUTH_CONTRACTOR_POLICY_URL);
  
      let testContractorUrl = environment.apiBaseUrl.concat(Constants.TEST_AUTH_CONTRACTOR_POLICY_URL)
  
      console.log(testContractorUrl);
  
      return this.http.get(testContractorUrl).map(
          data => {
  
            console.log(`http get ${testContractorUrl} - returned ok`);
  
            return data;
          }).catch(e => 
            { 
              console.log(`http get ${testContractorUrl} - returned error`);
  
              console.log(e); return Observable.of(e); 
            })
      };
  }