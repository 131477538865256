import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormElementBase } from '../form-element-base';
import { LocalStorageService } from 'src/app/service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseTranslateComponent } from 'src/app/shared/base-translate/base-translate.component';

@Component({
  selector: 'dynamic-element',
  templateUrl: './dynamic-element.component.html',
  styleUrls: ['./dynamic-element.component.sass']
})
export class DynamicElementComponent extends BaseTranslateComponent {

  @Input() element: FormElementBase<any>;
  @Input() form: FormGroup;

  question: any;
  get isValid() { return this.form.controls[this.element.key].valid; }
  constructor(protected storage: LocalStorageService,
    protected translate: TranslateService) { 
      super(storage,translate);

    }

}
