import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MemberNotRegisterdGuardService implements CanActivate {

  constructor(private service: AuthService, private router: Router) {

  }

  profile(): any {
    return this.service.currentUser.profile;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.service.isLoggedInObs().flatMap(x => {
      if (x) {
        const registeredAsMember = this.profile().memberid > 0
          && this.profile().membertickettype === 'Member'

        console.log(`registeredAsMember = ${registeredAsMember}`);

        if (registeredAsMember) {
          this.router.navigate(['register/allreadyregistered']);
        }

        console.log(`member id = ${this.profile().memberid} - canactivate - ${!registeredAsMember}`);

        return of(!registeredAsMember);
      }
      else {
        return of(false);
      }
    });
  }
}
