import { country } from '../interface/country';
import { invoiceMethod } from '../interface/invoicingMethod';
import { language } from '../interface/language';
import { companyType } from '../interface/companyType';
import { adressToType } from '../interface/adressToType';
import { memberActivity } from '../interface/memberActivity';
import { collectionPointActivity } from '../interface/collectionPointActivity';
import { productType } from '../interface/productType';


export const productTypes: productType[] = [
  { value: 'PT-AG', viewValue: 'Agriculture' },
  { value: 'PT-E1', viewValue: 'Earth Movers/Road Construction < 2m' },
  { value: 'PT-E2', viewValue: 'Earth Movers/Road Construction > 2m' },
  { value: 'PT-INA', viewValue: 'Industry Air' },
  { value: 'PT-INP', viewValue: 'Industry Solid' },
  { value: 'PT-PL', viewValue: 'Trucks & Busses' },
  { value: 'PT-TO', viewValue: 'Passenger' }
]
  
export const memberActivities: memberActivity[] = [
  { value: 'ma1', viewValue: 'Producer/manufacturer/constructor' },
  { value: 'ma2', viewValue: 'Garage/distributer vehicles' },
  { value: 'ma3', viewValue: 'Tyre specialist/retailer' },
  { value: 'ma4', viewValue: 'Fast Fitter' },
  { value: 'ma5', viewValue: 'Distributor' },
  { value: 'ma6', viewValue: 'Purchase group' },
  { value: 'ma7', viewValue: 'Controled distribution' },
  { value: 'ma8', viewValue: 'Transporter' }];
 
export const companyTypes: companyType[] = [
  { value: '1-vzw', viewValue: 'vzw' },
  { value: '2-bvba', viewValue: 'bvba' },
  { value: '3-nv', viewValue: 'nv' },
  { value: '4-unknown', viewValue: 'unknown' }
];

export const ATT_REGISTERED: string = 'ATT-REGISTERED';
export const ATT_OTHER: string = 'ATT-OTHER';

export const addressesToTypes: adressToType[] = [
  { value: ATT_REGISTERED, viewValue: 'Registered place of business', checked: true },
  { value: ATT_OTHER, viewValue: 'Other', checked: false },
];

export const AET_DEFAULT: string = 'AET-DEFAULT';
export const AET_CUSTOM: string = 'AET-CUSTOM';

export const addressExploitationTypes: adressToType[] = [
  { value: AET_DEFAULT, viewValue: 'Default', checked: true },
  { value: AET_CUSTOM, viewValue: 'Custom', checked: false },
];

export const invoiceMethods: invoiceMethod[] = [
  { value: 'INVM-EMAIL', viewValue: 'Email' },
  { value: 'INVM-ZOOMIT', viewValue: 'Zoom-it' },
  { value: 'INVM-MAIL', viewValue: 'Mail' },
  { value: 'INVM-PDFINVOICESYSTEM', viewValue: 'PDF Invoice System' }
];

export const dutch: string = '1-dutch';
export const french: string = '2-french';
export const english: string = '3-english';

export const languages: language[] = [
  { value: dutch, viewValue: 'Dutch' },
  { value: french, viewValue: 'French' },
  { value: english, viewValue: 'English' }
  // { value: '4-german', viewValue: 'German' }
];

export const countries: country[] = [
  { value: 'CNT-BE', viewValue: 'BELGIUM'},
  { value: 'CNT-NL', viewValue: 'NETHERLANDS'},
  { value: 'CNT-FR', viewValue: 'FRANCE'},
  { value: 'CNT-LU', viewValue: 'LUXEMBOURG'},
  { value: 'CNT-DE', viewValue: 'GERMANY'},
  { value: 'CNT-GB', viewValue: 'UNITED KINGDOM'},
  { value: 'CNT-AF', viewValue: 'AFGHANISTAN'},
  { value: 'CNT-AL', viewValue: 'ALBANIA'},
  { value: 'CNT-DZ', viewValue: 'ALGERIA'},
  { value: 'CNT-AS', viewValue: 'AMERICAN SAMOA'},
  { value: 'CNT-AD', viewValue: 'ANDORRA'},
  { value: 'CNT-AO', viewValue: 'ANGOLA'},
  { value: 'CNT-AI', viewValue: 'ANGUILLA'},
  { value: 'CNT-AQ', viewValue: 'ANTARCTICA'},
  { value: 'CNT-AG', viewValue: 'ANTIGUA AND BARBUDA'},
  { value: 'CNT-AR', viewValue: 'ARGENTINA'},
  { value: 'CNT-AM', viewValue: 'ARMENIA'},
  { value: 'CNT-AW', viewValue: 'ARUBA'},
  { value: 'CNT-AU', viewValue: 'AUSTRALIA'},
  { value: 'CNT-AT', viewValue: 'AUSTRIA'},
  { value: 'CNT-AZ', viewValue: 'AZERBAIJAN'},
  { value: 'CNT-BS', viewValue: 'BAHAMAS'},
  { value: 'CNT-BH', viewValue: 'BAHRAIN'},
  { value: 'CNT-BD', viewValue: 'BANGLADESH'},
  { value: 'CNT-BB', viewValue: 'BARBADOS'},
  { value: 'CNT-BY', viewValue: 'BELARUS'},
  { value: 'CNT-BZ', viewValue: 'BELIZE'},
  { value: 'CNT-BJ', viewValue: 'BENIN'},
  { value: 'CNT-BM', viewValue: 'BERMUDA'},
  { value: 'CNT-BT', viewValue: 'BHUTAN'},
  { value: 'CNT-BO', viewValue: 'BOLIVIA'},
  { value: 'CNT-BA', viewValue: 'BOSNIA HERZEGOWINA'},
  { value: 'CNT-BW', viewValue: 'BOTSWANA'},
  { value: 'CNT-BV', viewValue: 'BOUVET ISLAND'},
  { value: 'CNT-BR', viewValue: 'BRAZIL'},
  { value: 'CNT-IO', viewValue: 'BRITISH INDIAN OCEAN TERRITORY'},
  { value: 'CNT-BN', viewValue: 'BRUNEI DARUSSALAM'},
  { value: 'CNT-BG', viewValue: 'BULGARIA'},
  { value: 'CNT-BF', viewValue: 'BURKINA FASO'},
  { value: 'CNT-BI', viewValue: 'BURUNDI'},
  { value: 'CNT-KH', viewValue: 'CAMBODIA'},
  { value: 'CNT-CM', viewValue: 'CAMEROON'},
  { value: 'CNT-CA', viewValue: 'CANADA'},
  { value: 'CNT-CV', viewValue: 'CAPE VERDE'},
  { value: 'CNT-KY', viewValue: 'CAYMAN ISLANDS'},
  { value: 'CNT-CF', viewValue: 'CENTRAL AFRICAN REPUBLIC'},
  { value: 'CNT-TD', viewValue: 'CHAD'},
  { value: 'CNT-CL', viewValue: 'CHILE'},
  { value: 'CNT-CN', viewValue: 'CHINA'},
  { value: 'CNT-CX', viewValue: 'CHRISTMAS ISLAND'},
  { value: 'CNT-CC', viewValue: 'COCOS (KEELING) ISLANDS'},
  { value: 'CNT-CO', viewValue: 'COLOMBIA'},
  { value: 'CNT-KM', viewValue: 'COMOROS'},
  { value: 'CNT-CG', viewValue: 'CONGO'},
  { value: 'CNT-CK', viewValue: 'COOK ISLANDS'},
  { value: 'CNT-CR', viewValue: 'COSTA RICA'},
  { value: 'CNT-CI', viewValue: 'COTE D\'IVOIRE'},
  { value: 'CNT-HR', viewValue: 'CROATIA (Hrvatska)'},
  { value: 'CNT-CU', viewValue: 'CUBA'},
  { value: 'CNT-CY', viewValue: 'CYPRUS'},
  { value: 'CNT-CZ', viewValue: 'CZECH REPUBLIC'},
  { value: 'CNT-DK', viewValue: 'DENMARK'},
  { value: 'CNT-DJ', viewValue: 'DJIBOUTI'},
  { value: 'CNT-DM', viewValue: 'DOMINICA'},
  { value: 'CNT-DO', viewValue: 'DOMINICAN REPUBLIC'},
  { value: 'CNT-TL', viewValue: 'EAST TIMOR'},
  { value: 'CNT-EC', viewValue: 'ECUADOR'},
  { value: 'CNT-EG', viewValue: 'EGYPT'},
  { value: 'CNT-SV', viewValue: 'EL SALVADOR'},
  { value: 'CNT-GQ', viewValue: 'EQUATORIAL GUINEA'},
  { value: 'CNT-ER', viewValue: 'ERITREA'},
  { value: 'CNT-EE', viewValue: 'ESTONIA'},
  { value: 'CNT-ET', viewValue: 'ETHIOPIA'},
  { value: 'CNT-FK', viewValue: 'FALKLAND ISLANDS (MALVINAS)'},
  { value: 'CNT-FO', viewValue: 'FAROE ISLANDS'},
  { value: 'CNT-FJ', viewValue: 'FIJI'},
  { value: 'CNT-FI', viewValue: 'FINLAND'},
  { value: 'CNT-FX', viewValue: 'FRANCE, METROPOLITAN'},
  { value: 'CNT-GF', viewValue: 'FRENCH GUIANA'},
  { value: 'CNT-PF', viewValue: 'FRENCH POLYNESIA'},
  { value: 'CNT-TF', viewValue: 'FRENCH SOUTHERN TERRITORIES'},
  { value: 'CNT-GA', viewValue: 'GABON'},
  { value: 'CNT-GM', viewValue: 'GAMBIA'},
  { value: 'CNT-GE', viewValue: 'GEORGIA'},
  { value: 'CNT-GH', viewValue: 'GHANA'},
  { value: 'CNT-GI', viewValue: 'GIBRALTAR'},
  { value: 'CNT-GR', viewValue: 'GREECE'},
  { value: 'CNT-GL', viewValue: 'GREENLAND'},
  { value: 'CNT-GD', viewValue: 'GRENADA'},
  { value: 'CNT-GP', viewValue: 'GUADELOUPE'},
  { value: 'CNT-GU', viewValue: 'GUAM'},
  { value: 'CNT-GT', viewValue: 'GUATEMALA'},
  { value: 'CNT-GN', viewValue: 'GUINEA'},
  { value: 'CNT-GW', viewValue: 'GUINEA-BISSAU'},
  { value: 'CNT-GY', viewValue: 'GUYANA'},
  { value: 'CNT-HT', viewValue: 'HAITI'},
  { value: 'CNT-HM', viewValue: 'HEARD AND MC DONALD ISLANDS'},
  { value: 'CNT-HN', viewValue: 'HONDURAS'},
  { value: 'CNT-HK', viewValue: 'HONG KONG'},
  { value: 'CNT-HU', viewValue: 'HUNGARY'},
  { value: 'CNT-IS', viewValue: 'ICELAND'},
  { value: 'CNT-IN', viewValue: 'INDIA'},
  { value: 'CNT-ID', viewValue: 'INDONESIA'},
  { value: 'CNT-IR', viewValue: 'IRAN, ISLAMIC REPUBLIC'},
  { value: 'CNT-IQ', viewValue: 'IRAQ'},
  { value: 'CNT-IE', viewValue: 'IRELAND'},
  { value: 'CNT-IL', viewValue: 'ISRAEL'},
  { value: 'CNT-IT', viewValue: 'ITALY'},
  { value: 'CNT-JM', viewValue: 'JAMAICA'},
  { value: 'CNT-JP', viewValue: 'JAPAN'},
  { value: 'CNT-JO', viewValue: 'JORDAN'},
  { value: 'CNT-KZ', viewValue: 'KAZAKHSTAN'},
  { value: 'CNT-KE', viewValue: 'KENYA'},
  { value: 'CNT-KI', viewValue: 'KIRIBATI'},
  { value: 'CNT-KP', viewValue: 'KOREA, DEMOCRATIC PEOPLE\'S REPUBLIC'},
  { value: 'CNT-KR', viewValue: 'KOREA, REPUBLIC OF'},
  { value: 'CNT-KW', viewValue: 'KUWAIT'},
  { value: 'CNT-KG', viewValue: 'KYRGYZSTAN'},
  { value: 'CNT-LA', viewValue: 'LAO PEOPLE\'S DEMOCRATIC REPUBLIC'},
  { value: 'CNT-LV', viewValue: 'LATVIA'},
  { value: 'CNT-LB', viewValue: 'LEBANON'},
  { value: 'CNT-LS', viewValue: 'LESOTHO'},
  { value: 'CNT-LR', viewValue: 'LIBERIA'},
  { value: 'CNT-LY', viewValue: 'LIBYAN ARAB JAMAHIRIYA'},
  { value: 'CNT-LI', viewValue: 'LIECHTENSTEIN'},
  { value: 'CNT-LT', viewValue: 'LITHUANIA'},
  { value: 'CNT-MO', viewValue: 'MACAU'},
  { value: 'CNT-MK', viewValue: 'MACEDONIA'},
  { value: 'CNT-MG', viewValue: 'MADAGASCAR'},
  { value: 'CNT-MW', viewValue: 'MALAWI'},
  { value: 'CNT-MY', viewValue: 'MALAYSIA'},
  { value: 'CNT-MV', viewValue: 'MALDIVES'},
  { value: 'CNT-ML', viewValue: 'MALI'},
  { value: 'CNT-MT', viewValue: 'MALTA'},
  { value: 'CNT-MH', viewValue: 'MARSHALL ISLANDS'},
  { value: 'CNT-MQ', viewValue: 'MARTINIQUE'},
  { value: 'CNT-MR', viewValue: 'MAURITANIA'},
  { value: 'CNT-MU', viewValue: 'MAURITIUS'},
  { value: 'CNT-YT', viewValue: 'MAYOTTE'},
  { value: 'CNT-MX', viewValue: 'MEXICO'},
  { value: 'CNT-FM', viewValue: 'MICRONESIA, FEDERATED STATES OF'},
  { value: 'CNT-MD', viewValue: 'MOLDOVA, REPUBLIC OF'},
  { value: 'CNT-MC', viewValue: 'MONACO'},
  { value: 'CNT-MN', viewValue: 'MONGOLIA'},
  { value: 'CNT-MS', viewValue: 'MONTSERRAT'},
  { value: 'CNT-MA', viewValue: 'MOROCCO'},
  { value: 'CNT-MZ', viewValue: 'MOZAMBIQUE'},
  { value: 'CNT-MM', viewValue: 'MYANMAR'},
  { value: 'CNT-NA', viewValue: 'NAMIBIA'},
  { value: 'CNT-NR', viewValue: 'NAURU'},
  { value: 'CNT-NP', viewValue: 'NEPAL'},
  { value: 'CNT-AN', viewValue: 'NETHERLANDS ANTILLES'},
  { value: 'CNT-NC', viewValue: 'NEW CALEDONIA'},
  { value: 'CNT-NZ', viewValue: 'NEW ZEALAND'},
  { value: 'CNT-NI', viewValue: 'NICARAGUA'},
  { value: 'CNT-NE', viewValue: 'NIGER'},
  { value: 'CNT-NG', viewValue: 'NIGERIA'},
  { value: 'CNT-NU', viewValue: 'NIUE'},
  { value: 'CNT-NF', viewValue: 'NORFOLK ISLAND'},
  { value: 'CNT-MP', viewValue: 'NORTHERN MARIANA ISLANDS'},
  { value: 'CNT-NO', viewValue: 'NORWAY'},
  { value: 'CNT-OM', viewValue: 'OMAN'},
  { value: 'CNT-PK', viewValue: 'PAKISTAN'},
  { value: 'CNT-PW', viewValue: 'PALAU'},
  { value: 'CNT-PA', viewValue: 'PANAMA'},
  { value: 'CNT-PG', viewValue: 'PAPUA NEW GUINEA'},
  { value: 'CNT-PY', viewValue: 'PARAGUAY'},
  { value: 'CNT-PE', viewValue: 'PERU'},
  { value: 'CNT-PH', viewValue: 'PHILIPPINES'},
  { value: 'CNT-PN', viewValue: 'PITCAIRN'},
  { value: 'CNT-PL', viewValue: 'POLAND'},
  { value: 'CNT-PT', viewValue: 'PORTUGAL'},
  { value: 'CNT-PR', viewValue: 'PUERTO RICO'},
  { value: 'CNT-QA', viewValue: 'QATAR'},
  { value: 'CNT-RE', viewValue: 'REUNION'},
  { value: 'CNT-RO', viewValue: 'ROMANIA'},
  { value: 'CNT-RU', viewValue: 'RUSSIAN FEDERATION'},
  { value: 'CNT-RW', viewValue: 'RWANDA'},
  { value: 'CNT-KN', viewValue: 'SAINT KITTS AND NEVIS'},
  { value: 'CNT-LC', viewValue: 'SAINT LUCIA'},
  { value: 'CNT-VC', viewValue: 'SAINT VINCENT AND THE GRENADINES'},
  { value: 'CNT-WS', viewValue: 'SAMOA'},
  { value: 'CNT-SM', viewValue: 'SAN MARINO'},
  { value: 'CNT-ST', viewValue: 'SAO TOME AND PRINCIPE'},
  { value: 'CNT-SA', viewValue: 'SAUDI ARABIA'},
  { value: 'CNT-SN', viewValue: 'SENEGAL'},
  { value: 'CNT-SC', viewValue: 'SEYCHELLES'},
  { value: 'CNT-SL', viewValue: 'SIERRA LEONE'},
  { value: 'CNT-SG', viewValue: 'SINGAPORE'},
  { value: 'CNT-SK', viewValue: 'SLOVAKIA (Slovak Republic)'},
  { value: 'CNT-SI', viewValue: 'SLOVENIA'},
  { value: 'CNT-SB', viewValue: 'SOLOMON ISLANDS'},
  { value: 'CNT-SO', viewValue: 'SOMALIA'},
  { value: 'CNT-ZA', viewValue: 'SOUTH AFRICA'},
  { value: 'CNT-GS', viewValue: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS'},
  { value: 'CNT-ES', viewValue: 'SPAIN'},
  { value: 'CNT-LK', viewValue: 'SRI LANKA'},
  { value: 'CNT-SH', viewValue: 'ST. HELENA'},
  { value: 'CNT-PM', viewValue: 'ST. PIERRE AND MIQUELON'},
  { value: 'CNT-SD', viewValue: 'SUDAN'},
  { value: 'CNT-SR', viewValue: 'SURINAME'},
  { value: 'CNT-SJ', viewValue: 'SVALBARD AND JAN MAYEN ISLANDS'},
  { value: 'CNT-SZ', viewValue: 'SWAZILAND'},
  { value: 'CNT-SE', viewValue: 'SWEDEN'},
  { value: 'CNT-CH', viewValue: 'SWITZERLAND'},
  { value: 'CNT-SY', viewValue: 'SYRIAN ARAB REPUBLIC'},
  { value: 'CNT-TW', viewValue: 'TAIWAN'},
  { value: 'CNT-TJ', viewValue: 'TAJIKISTAN'},
  { value: 'CNT-TZ', viewValue: 'TANZANIA, UNITED REPUBLIC OF'},
  { value: 'CNT-TH', viewValue: 'THAILAND'},
  { value: 'CNT-TG', viewValue: 'TOGO'},
  { value: 'CNT-TK', viewValue: 'TOKELAU'},
  { value: 'CNT-TO', viewValue: 'TONGA'},
  { value: 'CNT-TT', viewValue: 'TRINIDAD AND TOBAGO'},
  { value: 'CNT-TN', viewValue: 'TUNISIA'},
  { value: 'CNT-TR', viewValue: 'TURKEY'},
  { value: 'CNT-TM', viewValue: 'TURKMENISTAN'},
  { value: 'CNT-TC', viewValue: 'TURKS AND CAICOS ISLANDS'},
  { value: 'CNT-TV', viewValue: 'TUVALU'},
  { value: 'CNT-UG', viewValue: 'UGANDA'},
  { value: 'CNT-UA', viewValue: 'UKRAINE'},
  { value: 'CNT-AE', viewValue: 'UNITED ARAB EMIRATES'},
  { value: 'CNT-US', viewValue: 'UNITED STATES'},
  { value: 'CNT-UM', viewValue: 'UNITED STATES MINOR OUTLYING ISLANDS'},
  { value: 'CNT-UY', viewValue: 'URUGUAY'},
  { value: 'CNT-UZ', viewValue: 'UZBEKISTAN'},
  { value: 'CNT-VU', viewValue: 'VANUATU'},
  { value: 'CNT-VA', viewValue: 'VATICAN CITY STATE (HOLY SEE)'},
  { value: 'CNT-VE', viewValue: 'VENEZUELA'},
  { value: 'CNT-VN', viewValue: 'VIET NAM'},
  { value: 'CNT-VG', viewValue: 'VIRGIN ISLANDS (BRITISH)'},
  { value: 'CNT-VI', viewValue: 'VIRGIN ISLANDS (U.S.)'},
  { value: 'CNT-WF', viewValue: 'WALLIS AND FUTUNA ISLANDS'},
  { value: 'CNT-EH', viewValue: 'WESTERN SAHARA'},
  { value: 'CNT-ZM', viewValue: 'ZAMBIA'},
  { value: 'CNT-ZW', viewValue: 'ZIMBABWE'}
];

export class Constants {
    
    public static get CAN_START_MEMBER_REGISTRATION_WORKFLOW_URL(): string { return "register/canstartmemberregistration/"; };
    public static get CAN_START_COLLECTIONPOINT_REGISTRATION_WORKFLOW_URL(): string { return "register/canstartcollectionpointregistration/"; };
    public static get CAN_START_NEW_CONTRACTOR_WORKFLOW_URL(): string { return "register/canstartnewcontractorworkflow/"; };
    
    public static get TICKET_SIGNUP_GET_URL(): string { return "register/signup/"; };

    public static get SIGNUP_CREATECONTRACTOR_POST_URL(): string { return "register/signup/createuseraccount"; };
    public static get SIGNUP_DISABLECONTRACTOR_DELETE_URL(): string { return "register/signup/disableuseraccount"; };

    public static get MEMBER_STARTUP_GET_URL(): string { return "register/member/startup"; };
    public static get MEMBER_STARTUP_POST_URL(): string { return "register/member/create"; };

    public static get COLLECTIONPOINT_STARTUP_GET_URL(): string { return "register/collectionpoint/startup"; };
    public static get COLLECTIONPOINT_STARTUP_POST_URL(): string { return "register/collectionpoint/create"; };

    public static get TEST_AUTH_ALWAYSTRUE_POLICY_URL(): string { return "test/testalwaystrue"; };
    public static get TEST_AUTH_CONTRACTOR_POLICY_URL(): string { return "test/testcontractor"; };

    public static get IBAN_VALIDATION_URL(): string { return "validate/iban/"; };

    public static get SWAGGER_URL(): string { return "swagger"; };


    public static Regex_url: string = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    public static Regex_positiveNumber: string = '^[+]?\\d*';

    public static Regex_Bic: string = '^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$';
}