import { Component, OnInit, ReflectiveInjector } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/service/local-storage.service';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-base-translate',
  templateUrl: './base-translate.component.html'
})
export class BaseTranslateComponent {

  constructor(protected storage: LocalStorageService, protected translate: TranslateService) {
    this.translate.use(this.storage.getLanguage().toLowerCase());
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
} 
