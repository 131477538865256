import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collectionpoint-allready-registered',
  templateUrl: './collectionpoint-allready-registered.component.html',
  styleUrls: ['./collectionpoint-allready-registered.component.sass']
})
export class CollectionpointAllreadyRegisteredComponent {

  constructor() { }
}
