import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../service/local-storage.service';
import { BaseTranslateComponent } from '../shared/base-translate/base-translate.component';

@Component({
  selector: 'app-activation-guid-not-valid',
  templateUrl: './activation-guid-not-valid.component.html',
  styleUrls: ['./activation-guid-not-valid.component.sass']
})
export class ActivationGuidNotValidComponent extends BaseTranslateComponent {

  constructor(protected storage: LocalStorageService,protected translate: TranslateService) {
    super(storage,translate);
  }
}
