import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseTranslateComponent } from 'src/app/shared/base-translate/base-translate.component';

@Component({
  selector: 'app-contractor-created-succesfull',
  templateUrl: './contractor-created-succesfull.component.html',
  styleUrls: ['./contractor-created-succesfull.component.sass']
})
export class ContractorCreatedSuccesfullComponent extends BaseTranslateComponent {

  constructor(protected storage: LocalStorageService,protected translate: TranslateService) { 
    super(storage,translate);
  }

}
