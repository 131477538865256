import { LocalStorageService } from './service/local-storage.service';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';

// material design modules
import { MatSelectModule} from '@angular/material/select';
import { MatInputModule, MatButtonModule, MatStepperModule, MatIconModule, MatDividerModule, MatRadioModule, MatExpansionModule, MatCheckboxModule, MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, MatSnackBarModule, MatProgressBarModule} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// translate
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { BaseComponent } from './shared/base/base.component';
import { TestComponent } from './test/test.component';


// authentication
import { ActivationGuidNotValidComponent } from './activation-guid-not-valid/activation-guid-not-valid.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationModule } from './authentication/authentication.module';

// directives
import { MustMatchDirective } from './must-match.directive';
import { SpecialCharacterDirective } from './directive/special-is-alpha-numeric.directive';

// user signup
import { UserSignupService } from './usersignup/usersingup.service';
import { UsersignupComponent } from './usersignup/usersignup.component';

// contractor
import { ContractorCreatedFailedComponent } from './usersignup/contractor-created-failed/contractor-created-failed.component';
import { ContractorCreatedSuccesfullComponent } from './usersignup/contractor-created-succesfull/contractor-created-succesfull.component';


// registration general
import { RegistrationHubComponent } from './registration-hub/registration-hub.component';

// member registration wizard
import { MemberRegistrationComponent, DialogCancelRegistrationDialog, DefaultSnackBarComponent } from './member-registration-wizard/member-registration.component';
import { MemberRegistrationService } from './member-registration-wizard/member-registration.service';
import { MemberCreatedSuccesfullComponent } from './member-registration-wizard/member-created-succesfull/member-created-succesfull.component';
import { MemberCreatedFailedComponent } from './member-registration-wizard/member-created-failed/member-created-failed.component';
import { MemberCreatedCancelledSuccessfullComponent } from './member-registration-wizard/member-created-cancelled-successfull/member-created-cancelled-successfull.component';
import { MemberCreatedCancelledFailedComponent } from './member-registration-wizard/member-created-cancelled-failed/member-created-cancelled-failed.component';
import { MemberAllreadyRegisteredComponent } from './member-registration-wizard/member-allready-registered/member-allready-registered.component';

// collectionpoint registration wizard
import { CollectionpointForecastStepComponent } from './collectionpoint-forecast-component-step/collectionpoint-forecast-step.component';

// collectionpoint registration wizard
import { CollectionpointRegistrationComponent } from './collectionpoint-registration-wizard/collectionpoint-registration.component';
import { CollectionPointRegistrationService } from './collectionpoint-registration-wizard/collectionpoint-registration.service';
import { CollectionpointCreatedFailedComponent } from './collectionpoint-registration-wizard/collectionpoint-created-failed/collectionpoint-created-failed.component';
import { CollectionpointCreatedSuccesfullComponent } from './collectionpoint-registration-wizard/collectionpoint-created-succesfull/collectionpoint-created-succesfull.component';
import { CollectionpointAllreadyRegisteredComponent } from './collectionpoint-registration-wizard/collectionpoint-allready-registered/collectionpoint-allready-registered.component';

// reusable components

// general
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';

// company
import { CompanyAddressComponent } from './company-address-step/company.address.component';
import { CompanyAdditionalStepComponent } from './company-additional-step/company-additional-step.component';

// country
import { CountrySelectComponent } from './country-select/country-select.component';

// test pages
import { TestCompanyAddressFormComponent } from './test/registration-wizard/test-company-adress-form/test-company-address-form.component';
import { TestCompanyAdditionalMemberFormComponent } from './test/registration-wizard/test-company-additional-address-member-form/test-company-additional-address-member-form';
import { TestCompanyAdditionalCollectionPointFormComponent } from './test/registration-wizard/test-company-additional-address-collectionpoint-form/test-company-additional-address-collectionpoint-form';
import { TestCollectionpointActivitiesFormComponent } from './test/registration-wizard/test-collectionpoint-activities-form/test-collectionpoint-activities-form.component';

import { AuthHttpInterceptor } from './interceptors/auth-http.interceptor';
import { TestService } from './test/test.service';
import { CollectionpointActivitiesStepComponent } from './collectionpoint-activities-step/collectionpoint-activities-step.component';

// dynamic forms
import { FormElementComponent } from './form-element/form-element.component';
import { DynamicElementComponent } from './elements/dynamic-element/dynamic-element.component';
import { TestCollectionpointForecastFormComponent } from './test/registration-wizard/test-collectionpoint-forecast-form/test-collectionpoint-forecast-form.component';
import { ActivityElementComponent } from './elements/activity-element/activity-element.component';
import { ForecastElementComponent } from './elements/forecast-element/forecast-element.component';
import { GenericStepComponent } from './generic-component-step/generic-step.component';
import { WasteElementComponent } from './elements/waste-element/waste-element.component';
import { CollectionpointCharterStepComponent } from './collectionpoint-charter-step/collectionpoint-charter-step.component';
import { TestCollectionpointCharterFormComponent } from './test/registration-wizard/test-collectionpoint-charter-form/test-collectionpoint-charter-form.component';
import { BaseTranslateComponent } from './shared/base-translate/base-translate.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { HttpLoaderInterceptor } from './service/http-loader.interceptor';
import { OfflineComponent } from './offline/offline.component';
import { BaseAutoLogoutComponent } from './base-auto-logout/base-auto-logout.component';
import { AddressToMethodComponent } from './address-to-method/address-to-method.component';
import { TestAddressLocationComponent } from './test-address-location/test-address-location.component';
import { DebounceClickDirective } from './shared/directive/debounce-click.directive';
import { IbanComponent } from './iban/iban.component';
import { IbanTestComponent } from './test/iban-test/iban-test.component';
import {ComponentFactoryService} from "./service/component-factory.service";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    LoaderComponent,
    AppComponent,
    BaseComponent,
    ForbiddenComponent,
    AppLayoutComponent,
    AuthLayoutComponent,
    TestComponent,
    PageNotFoundComponent,
    ActivationGuidNotValidComponent,
    UsersignupComponent,
    MustMatchDirective,
    ContractorCreatedFailedComponent,
    ContractorCreatedSuccesfullComponent,
    MemberRegistrationComponent,
    MemberCreatedSuccesfullComponent,
    MemberCreatedFailedComponent,
    MemberCreatedCancelledSuccessfullComponent,
    MemberCreatedCancelledFailedComponent,
    DialogCancelRegistrationDialog,
    DefaultSnackBarComponent,
    MemberAllreadyRegisteredComponent,
    CountrySelectComponent,
    RegistrationHubComponent,
    CollectionpointRegistrationComponent,
    CompanyAddressComponent,
    CompanyAdditionalStepComponent,
    TestCompanyAddressFormComponent,
    TestCompanyAdditionalMemberFormComponent,
    TestCompanyAdditionalCollectionPointFormComponent,
    CollectionpointCreatedFailedComponent,
    CollectionpointCreatedSuccesfullComponent,
    CollectionpointAllreadyRegisteredComponent,
    TestCollectionpointActivitiesFormComponent,
    CollectionpointActivitiesStepComponent,
    CollectionpointForecastStepComponent,
    FormElementComponent,
    DynamicElementComponent,
    TestCollectionpointForecastFormComponent,
    ActivityElementComponent,
    ForecastElementComponent,
    GenericStepComponent,
    WasteElementComponent,
    CollectionpointCharterStepComponent,
    TestCollectionpointCharterFormComponent,
    BaseTranslateComponent,
    OfflineComponent,
    BaseAutoLogoutComponent,
    AddressToMethodComponent,
    TestAddressLocationComponent,
    DebounceClickDirective,
    SpecialCharacterDirective,
    IbanComponent,
    IbanTestComponent
  ],
  imports: [
    FlexLayoutModule,
    BrowserModule,
    MatInputModule,
    AppRoutingModule,
    MatButtonModule,
    MatSelectModule,
    MatStepperModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthenticationModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    AngularFontAwesomeModule,
    MatDividerModule,
    MatRadioModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoaderInterceptor,
      multi: true,
    },
    ComponentFactoryService,
    LocalStorageService,
    TestService,
    UserSignupService,
    MemberRegistrationService,
    CollectionPointRegistrationService,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true}
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogCancelRegistrationDialog,DefaultSnackBarComponent]
})
export class AppModule { }
