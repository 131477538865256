import { Component, OnInit, forwardRef, OnDestroy, Input } from '@angular/core';
import { Constants, languages, addressExploitationTypes, AET_DEFAULT, AET_CUSTOM } from '../shared/constants';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  AsyncValidatorFn
} from '@angular/forms';
import {of, Subscription} from 'rxjs';
import { CustomErrorStateMatcher } from '../shared/CustomErrorStateMatcher';
import { BaseTranslateComponent } from '../shared/base-translate/base-translate.component';
import { LocalStorageService } from '../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { language } from '../interface/language';
import { adressToType } from '../interface/adressToType';
import {debounceTime, distinctUntilChanged, first, map, switchMap} from "rxjs/operators";
import {IbanService, IIbanValidationResult} from "../service/iban.service";

export interface CompanyAdditionalFormValues {
  exploitationAddressType: string;
  exploitationAddress: any;
  telephoneNumber: string;
  faxNumber: string;
  ibanInput: string;
  bic: string;
  preferredLanguage: string;
  firstNameOne: string;
  lastNameOne: string;
  emailOne: string;
  firstNameTwo: string;
  lastNameTwo: string;
  emailTwo: string;
}

@Component({
  selector: 'company-additional',
  templateUrl: './company-additional-step.component.html',
  styleUrls: ['./company-additional-step.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanyAdditionalStepComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CompanyAdditionalStepComponent),
      multi: true
    }
  ]
})
export class CompanyAdditionalStepComponent extends BaseTranslateComponent implements ControlValueAccessor, OnInit, OnDestroy {

  public form: FormGroup;

  secondRowVisible: boolean = false;

  localLanguages: language[] = languages;

  localExploitationAddressTypes: adressToType[] = addressExploitationTypes;

  subscriptions: Subscription[] = [];

  errorMatcher = new CustomErrorStateMatcher();

  _showAddress: Boolean = false;

  @Input() set showAddress(value: Boolean) {
    this._showAddress = value;

    this.setRequiredField();
  }

  get showAddress() : Boolean {
    return this._showAddress ;
  }

  private setRequiredField() {
    const exploitationAddressTypeControl = this.form.get('exploitationAddressType');

    if (this._showAddress) {
      exploitationAddressTypeControl.setValidators([Validators.required]);
    } else {
      exploitationAddressTypeControl.setValidators(null);
    }

    exploitationAddressTypeControl.updateValueAndValidity();
  }

  constructor(
    private formBuilder: FormBuilder,
    protected storage: LocalStorageService,
    protected translate: TranslateService,
    protected  ibanService: IbanService
  ) {
    super(storage, translate);

    this.form = this.formBuilder.group({
      exploitationAddressType: [AET_DEFAULT],
      exploitationAddress: this.formBuilder.control,
      preferredLanguage: ['dutch-1',Validators.required],
      telephoneNumber: new FormControl(null, [Validators.required]),
      faxNumber: new FormControl(),
      webSite: new FormControl(null, [Validators.pattern(Constants.Regex_url)]),
      ibanInput: new FormControl(null, [Validators.required],[this.validateIbanAsync()]),
      bic: new FormControl(null, [Validators.pattern(Constants.Regex_Bic)]),
      firstNameOne: new FormControl(null),
      lastNameOne: new FormControl(null),
      emailOne: new FormControl(null),
      firstNameTwo: new FormControl(null),
      lastNameTwo: new FormControl(null),
      emailTwo: new FormControl(null)
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      }));
  }

  validateIbanAsync(): AsyncValidatorFn {
    return control => {
      if (control && control.valueChanges) {
        console.log("control && control.valueChanges --> not null");

        return control.valueChanges
          .pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(value => this.ibanService.validate(value)),
            map((iIbanValidationResult: IIbanValidationResult) => (iIbanValidationResult.valid ? null : {'iban-invalid-format': true})),
            first());
      }
      else
      {
        console.log("control && control.valueChanges --> null");

        return of(null);
      }
    }
  };


  ngOnInit(): void {
    this.form.patchValue({
      exploitationAddress: AET_DEFAULT,
    });
  }

  get value(): CompanyAdditionalFormValues {
    return this.form.value;
  }

  set value(value: CompanyAdditionalFormValues) {
    this.form.setValue(value);

    this.onChange(value);

    this.onTouched();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => { };

  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {

    return this.form.valid ? null : { profile: { valid: false, }, };
  }


  get formGroupStepTwo(): AbstractControl | null {
    return this.form || null;
  }

  showSecondRow() {
    this.secondRowVisible = true;
  }

  get addUserDisabled(): boolean {
    return this.secondRowVisible;
  }

  get hideSecondRow(): boolean {
    return !this.secondRowVisible;
  }

  removeSecondRow() {
    this.secondRowVisible = false;

    const firstNameControl = this.formGroupStepTwo.get('firstNameTwo');
    const lastNameControl = this.formGroupStepTwo.get('lastNameTwo');
    const emailControl = this.formGroupStepTwo.get('emailTwo');

    firstNameControl.setValue(null);
    lastNameControl.setValue(null);
    emailControl.setValue(null)

    firstNameControl.setValidators(null);
    lastNameControl.setValidators(null);
    emailControl.setValidators(null);

    firstNameControl.updateValueAndValidity();
    lastNameControl.updateValueAndValidity();
    emailControl.updateValueAndValidity()
  }

  setUserRowOneValidation() {
    const firstNameControl = this.formGroupStepTwo.get('firstNameOne');
    const lastNameControl = this.formGroupStepTwo.get('lastNameOne');
    const emailControl = this.formGroupStepTwo.get('emailOne');

    if (this.userRowOneHasData) {
      firstNameControl.setValidators([Validators.required]);
      lastNameControl.setValidators([Validators.required]);
      emailControl.setValidators([Validators.required, Validators.email]);

    } else {
      firstNameControl.setValidators(null);
      lastNameControl.setValidators(null);
      emailControl.setValidators(null);
    }

    firstNameControl.updateValueAndValidity();
    lastNameControl.updateValueAndValidity();
    emailControl.updateValueAndValidity()
  }

  setUserRowTwoValidation() {
    const firstNameControl = this.formGroupStepTwo.get('firstNameTwo');
    const lastNameControl = this.formGroupStepTwo.get('lastNameTwo');
    const emailControl = this.formGroupStepTwo.get('emailTwo');

    if (this.userRowTwoHasData && this.secondRowVisible) {
      firstNameControl.setValidators([Validators.required]);
      lastNameControl.setValidators([Validators.required]);
      emailControl.setValidators([Validators.required, Validators.email]);

    } else {
      firstNameControl.setValidators(null);
      lastNameControl.setValidators(null);
      emailControl.setValidators(null);
    }

    firstNameControl.updateValueAndValidity();
    lastNameControl.updateValueAndValidity();
    emailControl.updateValueAndValidity()
  }

  get userRowOneHasData(): boolean {
    return this.formGroupStepTwo.get('firstNameOne').value ||
      this.formGroupStepTwo.get('lastNameOne').value ||
      this.formGroupStepTwo.get('emailOne').value;
  }

  get userRowTwoHasData(): boolean {
    return this.formGroupStepTwo.get('firstNameTwo').value ||
      this.formGroupStepTwo.get('lastNameTwo').value ||
      this.formGroupStepTwo.get('emailTwo').value;
  }

  isCustomSelected(): boolean {
    return this.form.get('exploitationAddressType').value === AET_CUSTOM;
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors = (control instanceof FormGroup || control instanceof FormArray)
        ? this.getAllErrors(control)
        : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
  }

}
