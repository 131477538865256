import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseTranslateComponent } from 'src/app/shared/base-translate/base-translate.component';
import { BaseAutoLogoutComponent } from 'src/app/base-auto-logout/base-auto-logout.component';
import { AuthService } from 'src/app/authentication/auth.service';
import { EnvironmentService } from 'src/app/service/environment.service';

@Component({
  selector: 'app-member-created-cancelled-failed',
  templateUrl: './member-created-cancelled-failed.component.html',
  styleUrls: ['./member-created-cancelled-failed.component.sass']
})
export class MemberCreatedCancelledFailedComponent extends BaseAutoLogoutComponent {

  constructor(protected environmentService: EnvironmentService,protected authService: AuthService, protected storage: LocalStorageService,protected translate: TranslateService) { 
    super(environmentService,authService,storage,translate);
  }
}
