import { Router } from '@angular/router';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Rx';

import { UserManager, User, UserManagerSettings } from 'oidc-client';
import { environment } from '../../environments/environment';
import { debug } from 'util';
// import { MessageService } from 'primeng/components/common/messageservice';


@Injectable()
export class AuthService {
  mgr: UserManager;
  userLoadedEvent: EventEmitter<User> = new EventEmitter<User>();
  currentUser: User = null;
  loggedIn = false;

  //authHeaders: Headers;

  constructor() {
      const settings = this.getClientSettings();

      console.log(settings);

      this.mgr = new UserManager(settings);

      this.mgr.getUser()
        .then((user) => {
          if (user) {
            this.loggedIn = true;
            this.currentUser = user;
            this.userLoadedEvent.emit(user);
            console.log("Logged in in authservice");
          }
          else {
            this.loggedIn = false;
          }
        })
        .catch((err) => {
          console.log("Identity server errror");

          console.log(err);
          this.loggedIn = false;
        });

      this.mgr.events.addUserLoaded((user) => {
        this.currentUser = user;
        this.loggedIn = !(user === undefined);
        if (!environment.production) {
          console.log('authService addUserLoaded', user);
        }

      });

      this.mgr.events.addUserUnloaded(() => {
        if (!environment.production) {          
          console.log('user unloaded');
        }

        this.loggedIn = false;
      });

      this.mgr.events.addAccessTokenExpired((e) => {
          console.log("Access token has expired");
      });
  }

  getClientSettings(): UserManagerSettings {
    return {
        authority: environment.authority,
        client_id: environment.client_id,
        redirect_uri: environment.redirect_uri,
        post_logout_redirect_uri: environment.post_logout_redirect_uri,
        silent_redirect_uri: environment.silent_redirect_uri,
        automaticSilentRenew: environment.automaticSilentRenew,
        response_type: environment.response_type,
        scope: environment.scope,
        filterProtocolClaims: environment.filterProtocolClaims,
        loadUserInfo: environment.loadUserInfo
    };
}

  getUser() {
    this.mgr.getUser().then((user) => {
      this.currentUser = user;
      console.log('got user', user);
      this.userLoadedEvent.emit(user);
    }).catch(function (err) {
      console.log(err);
    });
  }

  isLoggedInObs(): Observable<boolean> {
    return Observable.fromPromise(this.mgr.getUser()).map<User, boolean>((user) => {
      if (user != null && !user.expired) {
        return true;
      } else {
        return false;
      }
    });
  }

  getClaims(): any{
      return this.currentUser.profile;
  }

  getAuthorizationHeaderValue(): string {
      return `${this.currentUser.token_type} ${this.currentUser.access_token}`;
  }

  startAuthentication(): void{    
      this.mgr.signinRedirect().catch(error => {
        console.log('error', error);
        // this.messageService.add({ severity: 'error', summary: 'Something went wrong when trying to login.', detail: 'Try again or contact an administrator.' });
      });
  }

  completeAuthentication(): Promise<void> {
      return this.mgr.signinRedirectCallback().then(user=>{
          this.currentUser = user;
          this.userLoadedEvent.emit(user);
      }).catch(error => {
        console.log('error', error);
        // this.messageService.add({ severity: 'error', summary: 'Something went wrong when trying to login.', detail: 'Try again or contact an administrator.' });
      });
  } 

  silentRenewCallback(): Promise<User> {
      return this.mgr.signinSilentCallback();
  }

  startSignOutUser(): void {
    this.mgr.getUser().then(user => {
      return this.mgr.signoutRedirect({ id_token_hint: user.id_token }).then(resp => {
        console.log('signed out start', resp);
        setTimeout(() => {
          console.log('testing to see if fired...');
        },5000);
      }).catch(function (err) {
        console.log(err);
      });
    });
  }

  endSignoutMainWindow() {
    this.mgr.signoutRedirectCallback().then(function (resp) {
      console.log('signed out end', resp);
    }).catch(function (err) {
      console.log(err);
    });
  };
}


