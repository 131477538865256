

export class postMemberRegistrationRequest {


  public companyName : string = ''; 
  public companyType : string = '';

  public activationId: string = '';
  public telephoneNumber: string = '';
  public preferredLanguage: string = '';
  public faxNumber: string = '';
  public webSite: string = '';
  public iban: string = '';
  public bic: string = '';

  public department: string = '';
  public yourReference: string = '';

  public addressToType: string = '';
  public exploitationAddressType: string = '';
  
  public originalMarketImport: boolean = false;
  public originalMarketExport: boolean = false;

  public replacementMarketImport: boolean = false;
  public replacementMarketExport: boolean = false;

  public invoiceMethod: string = ''
  public invoiceEmail: string;
  
  constructor(  
    public activities: Array<memberActivityRequest> = new Array<memberActivityRequest>(),
    public imports: Array<importRequest> = new Array<importRequest>(),
    public companyAddress: adressRequest = new adressRequest(),
    public exploitationAddress: adressRequest = new adressRequest(),
    public otherAddress: adressRequest = new adressRequest(),
    public users: usersRequest = new usersRequest()
    ){

    }
}

export class usersRequest {
  constructor(  
  public firstNameOne: string = '',
  public lastNameOne: string = '',
  public emailOne: string = '',
  public firstNameTwo: string = '',
  public lastNameTwo: string = '',
  public emailTwo: string = ''){

  }
}

export class adressRequest {
  /**
   *
   */
  constructor( 
  public name: string = '',
  public street: string = '',
  public number: string = '',
  public addNumber : string = '',
  public postalCode : string = '',
  public city : string= '',
  public country: string= '') {
  }

}


export interface memberActivityRequest {
  code: string;
  value: boolean;
}

export interface importRequest {
  code: string;
  value: number;
}


