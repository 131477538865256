import { Component, OnInit } from '@angular/core';
import { AuthService } from '../authentication/auth.service';
import { BaseComponent } from '../shared/base/base.component';
import { BaseTranslateComponent } from '../shared/base-translate/base-translate.component';
import { LocalStorageService } from '../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '../service/environment.service';

@Component({
  selector: 'app-base-auto-logout',
  templateUrl: './base-auto-logout.component.html',
  styleUrls: ['./base-auto-logout.component.sass']
})

export class BaseAutoLogoutComponent extends BaseTranslateComponent implements OnInit {

  constructor(protected environmentService: EnvironmentService,protected authService: AuthService,protected storage: LocalStorageService, protected translate: TranslateService) {
    super(storage,translate);
    
  }

  get isTestEnvironment(): boolean {
    return this.environmentService.showDebugInfo;
  }

  ngOnInit(): void {
    (async () => {

      console.log('timer started')

      await this.delay(this.isTestEnvironment ? 200000 : 10000);

      console.log('start logging out')

      this.authService.startSignOutUser();

      console.log('logged out')
    })();

  }
}
