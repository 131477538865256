import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }

  public get isProd(): boolean {
    return environment.environmentName.toLowerCase() == 'production' || environment.production;
  }

  public get isIt(): boolean {
    return environment.environmentName.toLowerCase() == 'it';
  }

  public get isStaging(): boolean {
    return environment.environmentName.toLowerCase() == 'staging';
  }

  public get isTest(): boolean {
    return environment.environmentName.toLowerCase() == 'test';
  }

  public get isDev(): boolean {
    return environment.environmentName.toLowerCase() == 'dev';
  }

  public get showDebugInfo() : boolean {
    if (this.isProd || this.isIt) {
      return false;
    }

    return true;
  }

}
