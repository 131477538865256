import { BaseComponent } from './../../shared/base/base.component';
import { Router } from '@angular/router';
import { AuthService } from './../auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'auth-callback',
  templateUrl: './auth-callback.component.html'
})
export class AuthCallbackComponent extends BaseComponent implements OnInit {
    constructor(private authService: AuthService, private router: Router) {
        super();
     }

    ngOnInit() {
        this.authService.completeAuthentication().then(result => {
            this.router.navigate(['home']);
        });
    }
}