export class getMemberRegistrationStepOneResponse {
  vatNumber: string;
  companyName: string;
  companyTypeCode: string;
  companyTypeDescription: string;
  preferredLanguage: string;
  
  error: string;

  constructor(  
    public address: adressResponse = new adressResponse()
    )    {    }

  static createError(errorString: string) : getMemberRegistrationStepOneResponse {
    const response: getMemberRegistrationStepOneResponse = {
      vatNumber: '',
      companyName: '',
      companyTypeCode: '',
      companyTypeDescription: '',
      preferredLanguage: '',
      error: errorString,
      address: undefined
    }

    return response;
  }
}

export class adressResponse {
  /**
   *
   */
  constructor(  
  public street: string = '',
  public number: string = '',
  public addNumber : string = '',
  public postalCode : string = '',
  public city : string= '',
  public country: string= '') {
  }

}



