import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { language } from 'src/app/interface/language';
import { BaseTranslateComponent } from 'src/app/shared/base-translate/base-translate.component';
import { LocalStorageService } from 'src/app/service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-test-form',
  templateUrl: './test-company-address-form.component.html',
  styleUrls: ['./test-company-address-form.component.sass']
})
export class TestCompanyAddressFormComponent extends BaseTranslateComponent implements OnInit{
  mainForm: FormGroup;
  formattedMessage: string;
  isAutoFill :boolean;

  languages: language[] = [
    {value: '1-dutch', viewValue: 'Dutch'},
    {value: '2-french', viewValue: 'French'},
    {value: '3-english', viewValue: 'English'},
  ];

  constructor(private formBuilder: FormBuilder,
    protected storage: LocalStorageService,
    protected translate: TranslateService
  ) {
    super(storage,translate);

    this.mainForm = this.formBuilder.group({
      companyForm: [],
      autoFillCheckBox :new FormControl(),
      belgianCheckBox :new FormControl(),
      preferredLanguage :new FormControl()
    });

    this.mainForm.get('preferredLanguage').valueChanges.subscribe(val => {
      const shortLanguage = this.toShortLanguage(val);

      this.storage.setLanguage(shortLanguage);
      this.translate.use(shortLanguage);
    });
  }

  private toShortLanguage(longLanguage: string) : string {
    if(longLanguage === "1-dutch") {
      return "nl"
    }

    if(longLanguage === "2-french") {
      return "fr"
    }

    if(longLanguage === "3-english") {
      return "en"
    }

    if(longLanguage === "4-german") {
      return "de"
    }

    return "nl"
  }

  fillCompanyData()
  {
    console.log('fillCompanyData -- start');

    if (this.mainForm.value.belgianCheckBox){
      this.fillBelgianCompanyForm();
    }
    else {
      this.fillNonBelgianCompanyForm();
    }

    console.log('fillCompanyData -- end');

  }


  get companyFormValue()
  {
    return this.mainForm.get('companyForm').value;
  }

  get companyForm()
  {
    return this.mainForm.get('companyForm');
  }

  ngOnInit() {
    this.onChanges();
  }

  submit() {
    console.log(this.mainForm.value);
  }

  onChanges(): void {
    this.mainForm.get('autoFillCheckBox').valueChanges.subscribe(val => {
        console.log(this.mainForm.value);

        this.isAutoFill = val;
    });
  }

  fillBelgianCompanyForm() {
    console.log(`fillBelgianCompanyForm`)

    let belgianCompanyFormData = {
      activationId: '123',
      vatNumber: 'BE129273398',
      companyName: 'BMW Belgium',
      companyType: 'NV',
      preferredLanguage: '1-dutch',
      street: 'Vlamstraat',
      number: '1',
      addNumber: '2',
      postalCode: '3000',
      city: 'Antwerpen',
      country: 'CNT-BE'
    }

    this.mainForm.patchValue({ companyForm: belgianCompanyFormData });
  }

  fillNonBelgianCompanyForm() {
    console.log(`fillNonBelgianCompanyForm`)

    let nonBelgianCompanyFormData = {
      activationId: '123',
      vatNumber: 'DE129273398',
      companyName: 'BMW Belgium',
      companyType: '',
      preferredLanguage: '4-german',
      street: '',
      number: '',
      addNumber: '',
      postalCode: '',
      city: '',
      country: 'CNT-DE'
    }

    this.mainForm.patchValue({ companyForm: nonBelgianCompanyFormData });
  }
}
