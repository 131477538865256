import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { AuthService } from './../authentication/auth.service';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.headers.get("no-authorization")) 
    {
      return next.handle(request);
    }

    if(request.url.indexOf('assets/i18n') >= 0){
        return next.handle(request);
    }

    if(request.url.indexOf('iban') >= 0){
      return next.handle(request);
    }
  

    request = request.clone({
      setHeaders: { Authorization: this.authService.getAuthorizationHeaderValue()}
    });
    
    return next.handle(request);
  }
}