import { LocalStorageService } from '../../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import {MatSelectModule} from '@angular/material/select'; 
import { AuthService } from 'src/app/authentication/auth.service';
import { OnDestroy } from "@angular/core";
import { ISubscription } from "rxjs/Subscription";
import { User } from 'oidc-client';
import { Observable } from 'rxjs';

export interface Food {
  value: string;
  viewValue: string;
}

interface Language {
  name: string;
  locale: string;
}

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})

export class AppLayoutComponent implements OnInit {

  selectedLanguage: string;
  
  languages: any;

  authServiceLoadedSubscription: ISubscription;

  displayUserNav: boolean = false;

  firstName: string;
  lastName: string;
  accountName: string;
  _loadedUserSub: any;
  _loggedIn: boolean;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private storage: LocalStorageService) {
    this.translate.use(this.storage.getLanguage().toLowerCase());
    this.setLangs(this.storage.getLanguage());
    this.languages = [
      { label: 'const.languages.English', value: 'en' },
      { label: 'const.languages.Dutch', value: 'nl' },
      { label: 'const.languages.French', value: 'fr' }
    ];
  }

  setControlValues(user: User) {
    console.log(`setControlValues ${user.profile.language.toLowerCase()}`)

    this.firstName = user.profile.given_name;
    this.lastName = user.profile.family_name;
    this.accountName = user.profile.company;

    this.translate.use(user.profile.language.toLowerCase());
    this.setLangs(user.profile.language);
    this.storage.setLanguage(this.selectedLanguage);
  }

  ngOnInit() {
    if (this.authService.loggedIn)
    {
      this.setControlValues(this.authService.currentUser)
    } else
    {
      this.authServiceLoadedSubscription = this.authService.userLoadedEvent.subscribe( x=> 
        this.setControlValues(x))
    }
  }

  ngOnDestroy() {
    this.authServiceLoadedSubscription || this.authServiceLoadedSubscription.unsubscribe();
  }

  get profile(): any {
    return this.authService.currentUser.profile;
  }

  get userName(): string {
    return ` ${this.firstName} ${this.lastName} ` ;
  }


  toggleUserNav($event) {
    $event.preventDefault();
    if (this.displayUserNav == false) {
      this.displayUserNav = true;
    } else {
      this.displayUserNav = false;
    }
  }

  setLangs(chosenLang) {
    this.selectedLanguage = chosenLang.toLowerCase();
  }

  changeLang(event: any) {
    console.log(`language changed ui ${this.selectedLanguage.toLowerCase()}`)

    this.translate.use(this.selectedLanguage.toLowerCase());

    this.setLangs(this.selectedLanguage);
    
    this.storage.setLanguage(this.selectedLanguage);
  }

  logOut(): void {
    this.storage.clear();
    this.authService.startSignOutUser();
  }
}
