import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { adressToType } from '../interface/adressToType';
import { addressesToTypes } from '../shared/constants';

@Component({
  selector: 'app-test-address-location',
  templateUrl: './test-address-location.component.html',
  styleUrls: ['./test-address-location.component.sass']
})
export class TestAddressLocationComponent {
  public form: FormGroup;

  localAddressesToTypes: adressToType[] = addressesToTypes;

  constructor(private formBuilder: FormBuilder) { 

    this.form = this.formBuilder.group({
      addressToType: this.formBuilder.control,
      otherAddress: this.formBuilder.group({
          name: null,
          street: null,
          number: null,
          addNumber: null,
          postalCode: null,
          city: null,
          country: null
      }),
    });
  }

  isOtherSelected() : boolean {
    // console.log('addressToType: ' + this.form.get('addressToType').value)

    var control = this.form.get('addressToType');

    return this.form.get('addressToType').value === 'ATT-OTHER';
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
        const control = form.get(key);
        const errors = (control instanceof FormGroup || control instanceof FormArray)
            ? this.getAllErrors(control)
            : control.errors;
        if (errors) {
            acc[key] = errors;
            hasError = true;
        }
        return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
}
}
