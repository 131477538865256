import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from './auth.service';
import { RoleEnum, AllRoleEnums } from './role.enum';
import { UserSignupService } from '../usersignup/usersingup.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private authService: AuthService, private userSignupService: UserSignupService, private router: Router) { }

    private contains(haystack: Array<String>, needles: Array<String>) {
        for (var i = 0, len = needles.length; i < len; i++) {
            if (haystack.indexOf(needles[i]) != -1) {
                // found one so we can stop
                return true;
            }

        }
        // looped over all items , and did not find anything
        return false;
    }

    private isKnownRole(rolesToCheck: Array<String>) {
        return this.contains(AllRoleEnums, rolesToCheck)
    }

    canActivate(): Observable<boolean> {
        let isLoggedIn = this.authService.isLoggedInObs();

        isLoggedIn.subscribe((loggedin) => {
            if (!loggedin) {
                console.log("redirect to login");
                this.authService.startAuthentication();
            }
            else {
                if (this.authService.currentUser && this.authService.currentUser.profile) {
                    console.log('logged in role = ' + this.authService.currentUser.profile.role);


                    const rolesArray = String(this.authService.currentUser.profile.role)
                        .split(",", 15);

                    console.log(`check role array ${rolesArray}`);

                    if (!this.isKnownRole(rolesArray)) {
                        // not a known role
                        console.log("login role is invalid");
                        return of(false)
                    }


                    console.log("login role is valid");

                    return of(true);
                }
                else {
                    console.log("prohibited from routing to this route");
                    
                    this.router.navigate(['forbidden']);
                }
            }
        });
        return isLoggedIn;
    }
}