import { element } from 'protractor';
import { Component} from '@angular/core';
import { BaseTranslateComponent } from '../shared/base-translate/base-translate.component';
import { LocalStorageService } from '../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent extends BaseTranslateComponent {

  constructor(protected storage: LocalStorageService,protected translate: TranslateService) {
    super(storage,translate);
    
  }
}
