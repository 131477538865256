
export const environment = {
  production: false,
  environmentName: 'dev',
  apiBaseUrl: 'https://devapi.recytyre.be/api/',
  apiUrl: 'https://devapi.recytyre.be',
  authority: 'https://devidentityserver.recytyre.be/',
  client_id: 'my_recytyre_js',
  redirect_uri: 'https://devmy.recytyre.be/auth-callback',
  post_logout_redirect_uri: 'https://devmy.recytyre.be/',
  silent_redirect_uri: 'https://devmy.recytyre.be/silent-renew',
  automaticSilentRenew: true,
  response_type: 'id_token token',
  scope: 'openid profile api roles',
  filterProtocolClaims: true,
  loadUserInfo: true,
  buildInfo: "#{buildInfo}#",
  deployInfo: "#{deployInfo}#",
  appInsights: {
    instrumentationKey: '#{instrumentationKey}#'
  }
};