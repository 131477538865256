import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Guid } from '../shared/guid';
import { LoaderService } from '../loader/loader.service';


@Injectable()
export class HttpLoaderInterceptor implements HttpInterceptor {
    constructor(private loaderService: LoaderService) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const guid = Guid.NewGuid()
        this.showLoader(guid);
        return next.handle(req)
            .finally(() => this.hideLoader(guid));
    }
    private showLoader(id: Guid): void {
        this.loaderService.show(id);
    }
    private hideLoader(id: Guid): void {
        this.loaderService.hide(id);
    }
}
