export class getCollectionPointRegistrationStepOneResponse {
  vatNumber: string;
  companyName: string;
  companyTypeCode: string;
  companyTypeDescription: string;
  preferredLanguage: string;
  error: string;

  constructor(  
    public address: adressResponse = new adressResponse()
    )    {    
    
    }

    static createError(errorString: string) : getCollectionPointRegistrationStepOneResponse {
      const response: getCollectionPointRegistrationStepOneResponse = {
        vatNumber: '',
        companyName: '',
        companyTypeCode: '',
        companyTypeDescription: '',
        preferredLanguage: '',
        address: undefined,
        error: errorString
      }
  
      return response;
    }
}

export class adressResponse {
  /**
   *
   */
  constructor(  
  public street: string = '',
  public number: string = '',
  public addNumber : string = '',
  public postalCode : string = '',
  public city : string= '',
  public country: string= '') {
  }

}



