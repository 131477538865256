import { Component, OnInit, OnDestroy, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { addressesToTypes, invoiceMethods } from '../shared/constants';
import { collectionPointActivity } from '../interface/collectionPointActivity';
import { AtLeastOneHasAValue } from '../shared/function/AtLeastOneSelectedMultiple';
import { CollectionpointActivitiesStepService } from './collectionpoint-activities-step.service';
import { FormElementBase } from '../elements/form-element-base';
import { AtLeastOneSelected } from '../shared/function/AtLeastOneSelected';
import { BaseTranslateComponent } from '../shared/base-translate/base-translate.component';
import { LocalStorageService } from '../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { adressToType } from '../interface/adressToType';
import { invoiceMethod } from '../interface/invoicingMethod';

export interface CollectionPointActivitiesFormValues {
  activationId: string;

  activities: Array<collectionPointActivity>;
  relatedActivities: Array<collectionPointActivity>;
}

@Component({
  selector: 'collectionpoint-activities-step',
  templateUrl: './collectionpoint-activities-step.component.html',
  styleUrls: ['./collectionpoint-activities-step.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CollectionpointActivitiesStepComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CollectionpointActivitiesStepComponent),  
      multi: true
    }
  ]
})
export class CollectionpointActivitiesStepComponent extends BaseTranslateComponent implements ControlValueAccessor,OnDestroy,OnInit {
  activities: FormElementBase<any>[] = [];

  relatedActivities: FormElementBase<any>[] = [];

  public relatedActivitiesFormGroup: FormGroup;

  public activitiesFormGroup: FormGroup;

  localAddressesToTypes: adressToType[] = addressesToTypes;

  public form: FormGroup;

  subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder,private caService: CollectionpointActivitiesStepService,protected storage: LocalStorageService,protected translate: TranslateService) {
    super(storage,translate);

    this.activities = this.caService.getCollectionPointActivities();
    this.activitiesFormGroup = this.caService.toFormGroup(this.activities);

    this.relatedActivities = this.caService.getCollectionPointRelatedActivities();
    this.relatedActivitiesFormGroup = this.caService.toFormGroup(this.relatedActivities);

    this.form = this.formBuilder.group({
      addressToType: new FormControl('ATT-REGISTERED'),
      otherAddress: this.formBuilder.control,
      activities: this.activitiesFormGroup,
      relatedActivities: this.relatedActivitiesFormGroup
    }, 
    {validator: [AtLeastOneHasAValue('activities')]});
    
    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })); 
  }

  ngOnInit(): void {
    // this.form = this.caService.toFormGroup();

    // this.subscriptions.push(
    //   // any time the inner form changes update the parent of any change
    //   this.form.valueChanges.subscribe(value => {
    //     this.onChange(value);
    //     this.onTouched();
    //   })); 
  }

  get value(): CollectionPointActivitiesFormValues {
    return this.form.value;
  }

  set value(value: CollectionPointActivitiesFormValues) {

    Object.keys(value.activities).forEach(key => {
      
      const valueOf = value.activities[key];

      const control = this.activitiesFormGroup.get(valueOf);

      control.setValue(value.activities[key])
    })

    Object.keys(value.relatedActivities).forEach(key => {
      
      const control = this.relatedActivitiesFormGroup.get(key);

      control.setValue(value.relatedActivities[key] ? "YES" : "NO")
    })
    

    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  isOtherSelected() : boolean {
    //console.log('addressToType: ' + this.form.get('addressToType').value)

    const result = this.form.get('addressToType').value === 'ATT-OTHER';

    return result;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.form.valid ? null : { profile: { valid: false, }, };
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
        const control = form.get(key);
        const errors = (control instanceof FormGroup || control instanceof FormArray)
            ? this.getAllErrors(control)
            : control.errors;
        if (errors) {
            acc[key] = errors;
            hasError = true;
        }
        return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
}
}
