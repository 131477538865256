import { Component, OnInit } from '@angular/core';
import { country } from '../interface/country';


@Component({
  selector: 'country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.sass']
})
export class CountrySelectComponent implements OnInit {

  countries: country[] = [
    { value: 'CON-1', viewValue: 'BELGIUM'},
    { value: 'CON-2', viewValue: 'NETHERLANDS'},
    { value: 'CON-3', viewValue: 'FRANCE'},
    { value: 'CON-4', viewValue: 'LUXEMBOURG'},
    { value: 'CON-5', viewValue: 'GERMANY'},
    { value: 'CON-6', viewValue: 'UNITED KINGDOM'},
    { value: 'CON-101', viewValue: 'AFGHANISTAN'},
    { value: 'CON-102', viewValue: 'ALBANIA'},
    { value: 'CON-103', viewValue: 'ALGERIA'},
    { value: 'CON-104', viewValue: 'AMERICAN SAMOA'},
    { value: 'CON-105', viewValue: 'ANDORRA'},
    { value: 'CON-106', viewValue: 'ANGOLA'},
    { value: 'CON-107', viewValue: 'ANGUILLA'},
    { value: 'CON-108', viewValue: 'ANTARCTICA'},
    { value: 'CON-109', viewValue: 'ANTIGUA AND BARBUDA'},
    { value: 'CON-110', viewValue: 'ARGENTINA'},
    { value: 'CON-111', viewValue: 'ARMENIA'},
    { value: 'CON-112', viewValue: 'ARUBA'},
    { value: 'CON-113', viewValue: 'AUSTRALIA'},
    { value: 'CON-114', viewValue: 'AUSTRIA'},
    { value: 'CON-115', viewValue: 'AZERBAIJAN'},
    { value: 'CON-116', viewValue: 'BAHAMAS'},
    { value: 'CON-117', viewValue: 'BAHRAIN'},
    { value: 'CON-118', viewValue: 'BANGLADESH'},
    { value: 'CON-119', viewValue: 'BARBADOS'},
    { value: 'CON-120', viewValue: 'BELARUS'},
    { value: 'CON-121', viewValue: 'BELIZE'},
    { value: 'CON-122', viewValue: 'BENIN'},
    { value: 'CON-123', viewValue: 'BERMUDA'},
    { value: 'CON-124', viewValue: 'BHUTAN'},
    { value: 'CON-125', viewValue: 'BOLIVIA'},
    { value: 'CON-126', viewValue: 'BOSNIA HERZEGOWINA'},
    { value: 'CON-127', viewValue: 'BOTSWANA'},
    { value: 'CON-128', viewValue: 'BOUVET ISLAND'},
    { value: 'CON-129', viewValue: 'BRAZIL'},
    { value: 'CON-130', viewValue: 'BRITISH INDIAN OCEAN TERRITORY'},
    { value: 'CON-131', viewValue: 'BRUNEI DARUSSALAM'},
    { value: 'CON-132', viewValue: 'BULGARIA'},
    { value: 'CON-133', viewValue: 'BURKINA FASO'},
    { value: 'CON-134', viewValue: 'BURUNDI'},
    { value: 'CON-135', viewValue: 'CAMBODIA'},
    { value: 'CON-136', viewValue: 'CAMEROON'},
    { value: 'CON-137', viewValue: 'CANADA'},
    { value: 'CON-138', viewValue: 'CAPE VERDE'},
    { value: 'CON-139', viewValue: 'CAYMAN ISLANDS'},
    { value: 'CON-140', viewValue: 'CENTRAL AFRICAN REPUBLIC'},
    { value: 'CON-141', viewValue: 'CHAD'},
    { value: 'CON-142', viewValue: 'CHILE'},
    { value: 'CON-143', viewValue: 'CHINA'},
    { value: 'CON-144', viewValue: 'CHRISTMAS ISLAND'},
    { value: 'CON-145', viewValue: 'COCOS (KEELING) ISLANDS'},
    { value: 'CON-146', viewValue: 'COLOMBIA'},
    { value: 'CON-147', viewValue: 'COMOROS'},
    { value: 'CON-148', viewValue: 'CONGO'},
    { value: 'CON-149', viewValue: 'COOK ISLANDS'},
    { value: 'CON-150', viewValue: 'COSTA RICA'},
    { value: 'CON-151', viewValue: 'COTE D\'IVOIRE'},
    { value: 'CON-152', viewValue: 'CROATIA (Hrvatska)'},
    { value: 'CON-153', viewValue: 'CUBA'},
    { value: 'CON-154', viewValue: 'CYPRUS'},
    { value: 'CON-155', viewValue: 'CZECH REPUBLIC'},
    { value: 'CON-156', viewValue: 'DENMARK'},
    { value: 'CON-157', viewValue: 'DJIBOUTI'},
    { value: 'CON-158', viewValue: 'DOMINICA'},
    { value: 'CON-159', viewValue: 'DOMINICAN REPUBLIC'},
    { value: 'CON-160', viewValue: 'EAST TIMOR'},
    { value: 'CON-161', viewValue: 'ECUADOR'},
    { value: 'CON-162', viewValue: 'EGYPT'},
    { value: 'CON-163', viewValue: 'EL SALVADOR'},
    { value: 'CON-164', viewValue: 'EQUATORIAL GUINEA'},
    { value: 'CON-165', viewValue: 'ERITREA'},
    { value: 'CON-166', viewValue: 'ESTONIA'},
    { value: 'CON-167', viewValue: 'ETHIOPIA'},
    { value: 'CON-168', viewValue: 'FALKLAND ISLANDS (MALVINAS)'},
    { value: 'CON-169', viewValue: 'FAROE ISLANDS'},
    { value: 'CON-170', viewValue: 'FIJI'},
    { value: 'CON-171', viewValue: 'FINLAND'},
    { value: 'CON-172', viewValue: 'FRANCE, METROPOLITAN'},
    { value: 'CON-173', viewValue: 'FRENCH GUIANA'},
    { value: 'CON-174', viewValue: 'FRENCH POLYNESIA'},
    { value: 'CON-175', viewValue: 'FRENCH SOUTHERN TERRITORIES'},
    { value: 'CON-176', viewValue: 'GABON'},
    { value: 'CON-177', viewValue: 'GAMBIA'},
    { value: 'CON-178', viewValue: 'GEORGIA'},
    { value: 'CON-179', viewValue: 'GHANA'},
    { value: 'CON-180', viewValue: 'GIBRALTAR'},
    { value: 'CON-181', viewValue: 'GREECE'},
    { value: 'CON-182', viewValue: 'GREENLAND'},
    { value: 'CON-183', viewValue: 'GRENADA'},
    { value: 'CON-184', viewValue: 'GUADELOUPE'},
    { value: 'CON-185', viewValue: 'GUAM'},
    { value: 'CON-186', viewValue: 'GUATEMALA'},
    { value: 'CON-187', viewValue: 'GUINEA'},
    { value: 'CON-188', viewValue: 'GUINEA-BISSAU'},
    { value: 'CON-189', viewValue: 'GUYANA'},
    { value: 'CON-190', viewValue: 'HAITI'},
    { value: 'CON-191', viewValue: 'HEARD AND MC DONALD ISLANDS'},
    { value: 'CON-192', viewValue: 'HONDURAS'},
    { value: 'CON-193', viewValue: 'HONG KONG'},
    { value: 'CON-194', viewValue: 'HUNGARY'},
    { value: 'CON-195', viewValue: 'ICELAND'},
    { value: 'CON-196', viewValue: 'INDIA'},
    { value: 'CON-197', viewValue: 'INDONESIA'},
    { value: 'CON-198', viewValue: 'IRAN, ISLAMIC REPUBLIC'},
    { value: 'CON-199', viewValue: 'IRAQ'},
    { value: 'CON-200', viewValue: 'IRELAND'},
    { value: 'CON-201', viewValue: 'ISRAEL'},
    { value: 'CON-202', viewValue: 'ITALY'},
    { value: 'CON-203', viewValue: 'JAMAICA'},
    { value: 'CON-204', viewValue: 'JAPAN'},
    { value: 'CON-205', viewValue: 'JORDAN'},
    { value: 'CON-206', viewValue: 'KAZAKHSTAN'},
    { value: 'CON-207', viewValue: 'KENYA'},
    { value: 'CON-208', viewValue: 'KIRIBATI'},
    { value: 'CON-209', viewValue: 'KOREA, DEMOCRATIC PEOPLES REPUBLIC'},
    { value: 'CON-210', viewValue: 'KOREA, REPUBLIC OF'},
    { value: 'CON-211', viewValue: 'KUWAIT'},
    { value: 'CON-212', viewValue: 'KYRGYZSTAN'},
    { value: 'CON-213', viewValue: 'LAO PEOPLES DEMOCRATIC REPUBLIC'},
    { value: 'CON-214', viewValue: 'LATVIA'},
    { value: 'CON-215', viewValue: 'LEBANON'},
    { value: 'CON-216', viewValue: 'LESOTHO'},
    { value: 'CON-217', viewValue: 'LIBERIA'},
    { value: 'CON-218', viewValue: 'LIBYAN ARAB JAMAHIRIYA'},
    { value: 'CON-219', viewValue: 'LIECHTENSTEIN'},
    { value: 'CON-220', viewValue: 'LITHUANIA'},
    { value: 'CON-221', viewValue: 'MACAU'},
    { value: 'CON-222', viewValue: 'MACEDONIA'},
    { value: 'CON-223', viewValue: 'MADAGASCAR'},
    { value: 'CON-224', viewValue: 'MALAWI'},
    { value: 'CON-225', viewValue: 'MALAYSIA'},
    { value: 'CON-226', viewValue: 'MALDIVES'},
    { value: 'CON-227', viewValue: 'MALI'},
    { value: 'CON-228', viewValue: 'MALTA'},
    { value: 'CON-229', viewValue: 'MARSHALL ISLANDS'},
    { value: 'CON-230', viewValue: 'MARTINIQUE'},
    { value: 'CON-231', viewValue: 'MAURITANIA'},
    { value: 'CON-232', viewValue: 'MAURITIUS'},
    { value: 'CON-233', viewValue: 'MAYOTTE'},
    { value: 'CON-234', viewValue: 'MEXICO'},
    { value: 'CON-235', viewValue: 'MICRONESIA, FEDERATED STATES OF'},
    { value: 'CON-236', viewValue: 'MOLDOVA, REPUBLIC OF'},
    { value: 'CON-237', viewValue: 'MONACO'},
    { value: 'CON-238', viewValue: 'MONGOLIA'},
    { value: 'CON-239', viewValue: 'MONTSERRAT'},
    { value: 'CON-240', viewValue: 'MOROCCO'},
    { value: 'CON-241', viewValue: 'MOZAMBIQUE'},
    { value: 'CON-242', viewValue: 'MYANMAR'},
    { value: 'CON-243', viewValue: 'NAMIBIA'},
    { value: 'CON-244', viewValue: 'NAURU'},
    { value: 'CON-245', viewValue: 'NEPAL'},
    { value: 'CON-246', viewValue: 'NETHERLANDS ANTILLES'},
    { value: 'CON-247', viewValue: 'NEW CALEDONIA'},
    { value: 'CON-248', viewValue: 'NEW ZEALAND'},
    { value: 'CON-249', viewValue: 'NICARAGUA'},
    { value: 'CON-250', viewValue: 'NIGER'},
    { value: 'CON-251', viewValue: 'NIGERIA'},
    { value: 'CON-252', viewValue: 'NIUE'},
    { value: 'CON-253', viewValue: 'NORFOLK ISLAND'},
    { value: 'CON-254', viewValue: 'NORTHERN MARIANA ISLANDS'},
    { value: 'CON-255', viewValue: 'NORWAY'},
    { value: 'CON-256', viewValue: 'OMAN'},
    { value: 'CON-257', viewValue: 'PAKISTAN'},
    { value: 'CON-258', viewValue: 'PALAU'},
    { value: 'CON-259', viewValue: 'PANAMA'},
    { value: 'CON-260', viewValue: 'PAPUA NEW GUINEA'},
    { value: 'CON-261', viewValue: 'PARAGUAY'},
    { value: 'CON-262', viewValue: 'PERU'},
    { value: 'CON-263', viewValue: 'PHILIPPINES'},
    { value: 'CON-264', viewValue: 'PITCAIRN'},
    { value: 'CON-265', viewValue: 'POLAND'},
    { value: 'CON-266', viewValue: 'PORTUGAL'},
    { value: 'CON-267', viewValue: 'PUERTO RICO'},
    { value: 'CON-268', viewValue: 'QATAR'},
    { value: 'CON-269', viewValue: 'REUNION'},
    { value: 'CON-270', viewValue: 'ROMANIA'},
    { value: 'CON-271', viewValue: 'RUSSIAN FEDERATION'},
    { value: 'CON-272', viewValue: 'RWANDA'},
    { value: 'CON-273', viewValue: 'SAINT KITTS AND NEVIS'},
    { value: 'CON-274', viewValue: 'SAINT LUCIA'},
    { value: 'CON-275', viewValue: 'SAINT VINCENT AND THE GRENADINES'},
    { value: 'CON-276', viewValue: 'SAMOA'},
    { value: 'CON-277', viewValue: 'SAN MARINO'},
    { value: 'CON-278', viewValue: 'SAO TOME AND PRINCIPE'},
    { value: 'CON-279', viewValue: 'SAUDI ARABIA'},
    { value: 'CON-280', viewValue: 'SENEGAL'},
    { value: 'CON-281', viewValue: 'SEYCHELLES'},
    { value: 'CON-282', viewValue: 'SIERRA LEONE'},
    { value: 'CON-283', viewValue: 'SINGAPORE'},
    { value: 'CON-284', viewValue: 'SLOVAKIA (Slovak Republic)'},
    { value: 'CON-285', viewValue: 'SLOVENIA'},
    { value: 'CON-286', viewValue: 'SOLOMON ISLANDS'},
    { value: 'CON-287', viewValue: 'SOMALIA'},
    { value: 'CON-288', viewValue: 'SOUTH AFRICA'},
    { value: 'CON-289', viewValue: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS'},
    { value: 'CON-290', viewValue: 'SPAIN'},
    { value: 'CON-291', viewValue: 'SRI LANKA'},
    { value: 'CON-292', viewValue: 'ST. HELENA'},
    { value: 'CON-293', viewValue: 'ST. PIERRE AND MIQUELON'},
    { value: 'CON-294', viewValue: 'SUDAN'},
    { value: 'CON-295', viewValue: 'SURINAME'},
    { value: 'CON-296', viewValue: 'SVALBARD AND JAN MAYEN ISLANDS'},
    { value: 'CON-297', viewValue: 'SWAZILAND'},
    { value: 'CON-298', viewValue: 'SWEDEN'},
    { value: 'CON-299', viewValue: 'SWITZERLAND'},
    { value: 'CON-300', viewValue: 'SYRIAN ARAB REPUBLIC'},
    { value: 'CON-301', viewValue: 'TAIWAN'},
    { value: 'CON-302', viewValue: 'TAJIKISTAN'},
    { value: 'CON-303', viewValue: 'TANZANIA, UNITED REPUBLIC OF'},
    { value: 'CON-304', viewValue: 'THAILAND'},
    { value: 'CON-305', viewValue: 'TOGO'},
    { value: 'CON-306', viewValue: 'TOKELAU'},
    { value: 'CON-307', viewValue: 'TONGA'},
    { value: 'CON-308', viewValue: 'TRINIDAD AND TOBAGO'},
    { value: 'CON-309', viewValue: 'TUNISIA'},
    { value: 'CON-310', viewValue: 'TURKEY'},
    { value: 'CON-311', viewValue: 'TURKMENISTAN'},
    { value: 'CON-312', viewValue: 'TURKS AND CAICOS ISLANDS'},
    { value: 'CON-313', viewValue: 'TUVALU'},
    { value: 'CON-314', viewValue: 'UGANDA'},
    { value: 'CON-315', viewValue: 'UKRAINE'},
    { value: 'CON-316', viewValue: 'UNITED ARAB EMIRATES'},
    { value: 'CON-317', viewValue: 'UNITED STATES'},
    { value: 'CON-318', viewValue: 'UNITED STATES MINOR OUTLYING ISLANDS'},
    { value: 'CON-319', viewValue: 'URUGUAY'},
    { value: 'CON-320', viewValue: 'UZBEKISTAN'},
    { value: 'CON-321', viewValue: 'VANUATU'},
    { value: 'CON-322', viewValue: 'VATICAN CITY STATE (HOLY SEE)'},
    { value: 'CON-323', viewValue: 'VENEZUELA'},
    { value: 'CON-324', viewValue: 'VIET NAM'},
    { value: 'CON-325', viewValue: 'VIRGIN ISLANDS (BRITISH)'},
    { value: 'CON-326', viewValue: 'VIRGIN ISLANDS (U.S.)'},
    { value: 'CON-327', viewValue: 'WALLIS AND FUTUNA ISLANDS'},
    { value: 'CON-328', viewValue: 'WESTERN SAHARA'},
    { value: 'CON-329', viewValue: 'YEMEN'},
    { value: 'CON-330', viewValue: 'YUGOSLAVIA'},
    { value: 'CON-331', viewValue: 'ZAIRE'},
    { value: 'CON-332', viewValue: 'ZAMBIA'},
    { value: 'CON-333', viewValue: 'ZIMBABWE'},
    
  ];

  constructor() { }

  ngOnInit() {

  }

}
