import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { language } from 'src/app/interface/language';
import { LocalStorageService } from 'src/app/service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-test-collectionpoint-forecast-form',
  templateUrl: './test-collectionpoint-forecast-form.component.html',
  styleUrls: ['./test-collectionpoint-forecast-form.component.sass']
})
export class TestCollectionpointForecastFormComponent implements OnInit {
  mainForm: FormGroup;

  languages: language[] = [
    {value: '1-dutch', viewValue: 'Dutch'},
    {value: '2-french', viewValue: 'French'},
    {value: '3-english', viewValue: 'English'},
  ];

  constructor(private formBuilder: FormBuilder,
    protected storage: LocalStorageService,
    protected translate: TranslateService) {
    this.mainForm = this.formBuilder.group({
      foreCastForm: [],
      autoFillCheckBox :new FormControl(),
      preferredLanguage :new FormControl(),
      belgianCheckBox :new FormControl()
    });

    this.mainForm.get('preferredLanguage').valueChanges.subscribe(val => {
      const shortLanguage = this.toShortLanguage(val);

      this.storage.setLanguage(shortLanguage);
      this.translate.use(shortLanguage);
    });
  }

  private toShortLanguage(longLanguage: string) : string {
    if(longLanguage === "1-dutch") {
      return "nl"
    }

    if(longLanguage === "2-french") {
      return "fr"
    }

    if(longLanguage === "3-english") {
      return "en"
    }

    if(longLanguage === "4-german") {
      return "de"
    }

    return "nl"
  }

  fillForecastFormData()
  {
    console.log('fillForecastFormData -- start');

    this.fillAdditionalCompanyForm(); 

    console.log('fillForecastFormData -- end');
  }

  get formValue()
  {
    return this.mainForm.get('foreCastForm').value;
  }

  get form()
  {
    return this.mainForm.get('foreCastForm');
  }

  ngOnInit() {
    this.onChanges();
  }

  submit() {
    console.log(this.mainForm.value);
  }

  onChanges(): void {
    this.mainForm.get('autoFillCheckBox').valueChanges.subscribe(val => {
        console.log(this.mainForm.value);
    });
  }

  fillAdditionalCompanyForm() {
    console.log(`fillBelgianCompanyForm`)

    let additionalCompanyFormData = {
      activationId: "836A0F6F-C88A-468F-A024-AA02BE5B4B18"
    }

    this.mainForm.patchValue({ foreCastForm: additionalCompanyFormData });
  }

}
