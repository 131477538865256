import { Injectable } from '@angular/core';

import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { UserSignupService } from 'src/app/usersignup/usersingup.service';

@Injectable({
  providedIn: 'root'
})
export class TicketGuardService {

  constructor(private _service: UserSignupService, private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let id = next.paramMap.get('id');

    console.log(`canActivate -- ${id} --`)

    return this._service.canStartFlow(id,'CONTRACTOR_REGISTRATION').map( r => 
    {
      if(r)
      {
        console.log(`canActivate -- ${id} -- canStartNewContractorWorkFlow - true -- no error`)
            
        return true;
      }
      else
      {
        console.log(`canActivate -- ${id} -- canStartNewContractorWorkFlow - false -- no error`)

        return this._router.parseUrl('signup/guidnotvalid');
      }
    },
      error => {
        console.log(`canActivate -- ${id} -- error`)

        return this._router.parseUrl('signup/guidnotvalid');
      });
  }


  // }
}
