import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { countries, invoiceMethods, memberActivities, addressesToTypes } from '../shared/constants';
import { MemberRegistrationService } from './member-registration.service';
import { AuthService } from '../authentication/auth.service';
import { memberActivity } from '../interface/memberActivity';
import { adressToType } from '../interface/adressToType';
import { AtLeastOneHasAValue } from '../shared/function/AtLeastOneSelectedMultiple';
import { CustomErrorStateMatcher } from '../shared/CustomErrorStateMatcher';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material';
import { postMemberRegistrationRequest, importRequest, memberActivityRequest } from './postMemberRegistrationRequest';
import { invoiceMethod } from '../interface/invoicingMethod';
import { getMemberRegistrationStepOneResponse } from './getMemberRegistrationStepOneResponse';
import { country } from '../interface/country';
import { DialogCancelRegistrationData } from './DialogCancelRegistrationData';
import { Constants } from '../shared/constants';
import { EnvironmentService } from '../service/environment.service';
import { BaseTranslateComponent } from '../shared/base-translate/base-translate.component';
import { LocalStorageService } from '../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { AtLeastOneOfFourSelected } from '../shared/function/AtLeastOneOfFourSelected';

@Component({
  selector: 'app-member-registration',
  templateUrl: './member-registration.component.html',
  styleUrls: ['./member-registration.component.sass']
})
export class MemberRegistrationComponent extends BaseTranslateComponent implements OnInit {

  formGroup: FormGroup;

  isAutoFill: boolean = true;

  durationInSeconds = 5;

  isLinear: boolean = true;

  public userId: Number;

  selectedInvoicingMethod: string;

  errorMatcher = new CustomErrorStateMatcher();

  localMemberActivities: memberActivity[] = memberActivities;

  localAddressesToTypes: adressToType[] = addressesToTypes;

  formSubmitted: boolean;

  postCreateMemberRequest: postMemberRegistrationRequest;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private service: MemberRegistrationService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private environmentService: EnvironmentService,
    protected storage: LocalStorageService,
    protected translate: TranslateService
  ) {

    super(storage,translate);

    this.formGroup = this.formBuilder.group({
      formArray: this.formBuilder.array([
        this.formBuilder.group({ companyForm: [] }),
        this.formBuilder.group({ companyAdditionalForm: [] }),
        this.formBuilder.group({
          activationId: new FormControl({ value: null, hidden: true }, [Validators.required]),
          invoiceMethod: new FormControl({ value: 'INVM-EMAIL', disabled: false }),
          invoiceEmail: new FormControl({ value: null, disabled: false }),
          addressToType: new FormControl('ATT-REGISTERED'),
          invoiceStreet: new FormControl({ value: null, disabled: true }),
          invoiceNumber: new FormControl({ value: null, disabled: true }),
          invoiceAddNumber: new FormControl({ value: null, disabled: true }),
          invoicePostalCode: new FormControl({ value: null, disabled: true }),
          invoiceCity: new FormControl({ value: null, disabled: true }),
          invoiceCountry: new FormControl({ value: 'CNT-BE', disabled: true }),
          department: new FormControl(),
          yourReference: new FormControl(),
          originalMarketImport: new FormControl(),
          originalMarketExport: new FormControl(),
          replacementMarketImport: new FormControl(),
          replacementMarketExport: new FormControl(),
          activities: this.formBuilder.group({
            ma1: new FormControl(),
            ma2: new FormControl(),
            ma3: new FormControl(),
            ma4: new FormControl(),
            ma5: new FormControl(),
            ma6: new FormControl(),
            ma7: new FormControl(),
            ma8: new FormControl()
          }),
          imports: this.formBuilder.group({

            vt1: new FormControl([Validators.pattern(Constants.Regex_url)]),
            vt1Description: new FormControl({ value: null, disabled: true }),
            vt2: new FormControl([Validators.pattern(Constants.Regex_url)]),
            vt2Description: new FormControl({ value: null, disabled: true }),
            vt3: new FormControl([Validators.pattern(Constants.Regex_url)]),
            vt3Description: new FormControl({ value: null, disabled: true }),
            vt4: new FormControl([Validators.pattern(Constants.Regex_url)]),
            vt4Description: new FormControl({ value: null, disabled: true }),
            vt5: new FormControl([Validators.pattern(Constants.Regex_url)]),
            vt5Description: new FormControl({ value: null, disabled: true }),
            vt6: new FormControl([Validators.pattern(Constants.Regex_url)]),
            vt6Description: new FormControl({ value: null, disabled: true }),
            vt7: new FormControl([Validators.pattern(Constants.Regex_url)]),
            vt7Description: new FormControl({ value: null, disabled: true }),
            vt8: new FormControl([Validators.pattern(Constants.Regex_url)]),
            vt8Description: new FormControl({ value: null, disabled: true })
          })
        },
        {
            validator: [
            AtLeastOneOfFourSelected('originalMarketImport',
            'originalMarketExport',
            'replacementMarketImport',
            'replacementMarketExport'),
            AtLeastOneHasAValue('activities'),
            AtLeastOneHasAValue('imports')]
        }
      )
    ])
  });
  }

  localCountries: country[] = countries;

  localInvoiceMethods: invoiceMethod[] = invoiceMethods;

  get showDebugInfo(): boolean {
    return this.environmentService.showDebugInfo;
  }

  ngOnInit() {
    this.postInit();
  }

  get formArray(): AbstractControl | null {
    return this.formGroup.get('formArray') || null;
  }

  get formGroupStepOne(): AbstractControl | null {
    return this.formArray && this.formArray.get([0]) || null;
  }

  get companyFormGroup(): AbstractControl | null {
    return this.formArray && this.formArray.get([0]) && this.formArray.get([0]).get('companyForm') || null;
  }

  get companyAdditionalFormGroup(): AbstractControl | null {
    return this.formArray && this.formArray.get([1]) && this.formArray.get([1]).get('companyAdditionalForm') || null;
  }

  get formGroupStepTwo(): AbstractControl | null {
    return this.formArray && this.formArray.get([1]) || null;
  }

  get formGroupStepThree(): AbstractControl | null {
    return this.formArray && this.formArray.get([2]) || null;
  }

  get formGroupStepOneIsValid(): boolean {
    return this.formGroupStepOne && false
  }

  openDialogCancelRegistration(): void {
    console.log('clicked cancel');

    const dialogRef = this.dialog.open(DialogCancelRegistrationDialog, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      console.log(JSON.stringify(result));

      if (result && result.confirm) {
        this.service.cancelRegistration().subscribe(() => {
          this.openSnackBar('Cancel registration succeeded!');
          setTimeout(()=>{ this.logOut(); }, 2000);
        },
          e => {
            this.openSnackBar('Cancel registration failed!');

            console.log(e);
          })
      }
    });


  }

  logOut(): void {
    this.storage.clear();
    this.authService.startSignOutUser();
  }

  profile(): any {
    return this.authService.currentUser.profile;
  }


  fillBelgianCompanyForm(response: getMemberRegistrationStepOneResponse) {
    console.log(`fillBelgianCompanyForm -- ${response.vatNumber}`)

    if (!response || !response.address ) {
      return;
    }

    let belgianCompanyFormData = {
      activationId: this.authService.currentUser.profile.activationguid,
      vatNumber: response.vatNumber,
      name: response.companyName,
      companyType: response.companyTypeDescription,
      street: response.address.street,
      number: response.address.number,
      addNumber: response.address.addNumber,
      postalCode: response.address.postalCode,
      city: response.address.city,
      country: response.address.country,
    }

    this.formGroupStepOne.patchValue({ companyForm: belgianCompanyFormData});

    this.fillAdditionCompany(response);
  }

  fillNonBelgianCompanyForm(response: getMemberRegistrationStepOneResponse) {
    console.log(`fillNonBelgianCompanyForm -- ${response.vatNumber}`)

    this.isAutoFill = false;

    let nonBelgianCompanyFormData = {
      activationId: this.authService.currentUser.profile.activationguid,
      vatNumber: response.vatNumber,
      name: "",
      companyType: "",
      street: "",
      number: "",
      addNumber: "",
      postalCode: "",
      city: "",
      country: `CNT-${response.vatNumber.substr(0, 2)}`
    }

    this.formGroupStepOne.patchValue({ companyForm: nonBelgianCompanyFormData });

    this.fillAdditionCompany(response);
  }

  fillAdditionCompany(response: getMemberRegistrationStepOneResponse) {
    let belgianAdditionalCompanyFormData = {
      exploitationAddressType: "AET-DEFAULT",
      exploitationAddress: null,
      preferredLanguage: response.preferredLanguage,
      telephoneNumber: null,
      faxNumber: null,
      webSite: null,
      ibanInput: null,
      bic: null,
      firstNameOne: null,
      lastNameOne: null,
      emailOne: null,
      firstNameTwo: null,
      lastNameTwo: null,
      emailTwo: null
    };

    this.formGroupStepTwo.patchValue({ companyAdditionalForm: belgianAdditionalCompanyFormData });
  }

  postInit() {
    const memberId = new Number(this.profile().memberid);

    console.log(`memberId = ${this.profile().memberid}`);

    console.log(`memberId = ${memberId}`);

    if (memberId > 0 && false) {
      this.router.navigate(['/member/register/allreadyregistered']);

      return;
    }

    this.service.getMemberStartup(this.profile().activationguid).subscribe(r => {
      if(r.error) {
        console.log(`postInit -- getMemberRegistrationStepOne -- error --> ${r.error}`)

        this.openSnackBar(r.error)

        return;
      }

      console.log(r.address.country)

      if (r.vatNumber.startsWith("BE")) {
        this.fillBelgianCompanyForm(r);
      } else {
        this.fillNonBelgianCompanyForm(r);
      }

      this.formGroupStepOne.markAsUntouched();
      this.formGroupStepTwo.markAsUntouched();
      this.formGroupStepThree.markAsUntouched();
    }
      ,
      error => {
        console.log(`postInit -- getMemberRegistrationStepOne -- error --> ${error}`)
      });
  }


  onGroupOneSubmit() {
    console.log(this.formGroupStepOne.value);
  }

  onGroupTwoSubmit() {
    console.log(this.formGroupStepTwo.value);
  }

  formGroupSubmit() {
    this.formSubmitted = true;

    this.postCreateMemberRequest = new postMemberRegistrationRequest();

    this.postCreateMemberRequest.companyName = this.companyFormGroup.value.name || "";
    this.postCreateMemberRequest.companyType = this.companyFormGroup.value.companyType || "";
    // formgroup 1
    this.postCreateMemberRequest.companyAddress.name = "-";
    this.postCreateMemberRequest.companyAddress.street = this.companyFormGroup.value.street || "";
    this.postCreateMemberRequest.companyAddress.number = this.companyFormGroup.value.number || "";
    this.postCreateMemberRequest.companyAddress.addNumber = this.companyFormGroup.value.addNumber || "";
    this.postCreateMemberRequest.companyAddress.postalCode = this.companyFormGroup.value.postalCode || "";
    this.postCreateMemberRequest.companyAddress.city = this.companyFormGroup.value.city || "";
    this.postCreateMemberRequest.companyAddress.country = this.companyFormGroup.value.country || "";

    // companyAdditionalFormGroup - exploitationaddress
    this.postCreateMemberRequest.exploitationAddressType = this.companyAdditionalFormGroup.value.exploitationAddressType;
    this.postCreateMemberRequest.exploitationAddress.name = "-";
    this.postCreateMemberRequest.exploitationAddress.street = this.companyAdditionalFormGroup.value.exploitationAddress.street || "";
    this.postCreateMemberRequest.exploitationAddress.number = this.companyAdditionalFormGroup.value.exploitationAddress.number || "";
    this.postCreateMemberRequest.exploitationAddress.addNumber = this.companyAdditionalFormGroup.value.exploitationAddress.addNumber || "";
    this.postCreateMemberRequest.exploitationAddress.postalCode = this.companyAdditionalFormGroup.value.exploitationAddress.postalCode || "";
    this.postCreateMemberRequest.exploitationAddress.city = this.companyAdditionalFormGroup.value.exploitationAddress.city || "";
    this.postCreateMemberRequest.exploitationAddress.country = this.companyAdditionalFormGroup.value.exploitationAddress.country || ""

    this.postCreateMemberRequest.preferredLanguage = this.companyAdditionalFormGroup.value.preferredLanguage || "";
    this.postCreateMemberRequest.telephoneNumber = this.companyAdditionalFormGroup.value.telephoneNumber || "";
    this.postCreateMemberRequest.webSite = this.companyAdditionalFormGroup.value.webSite || "";
    this.postCreateMemberRequest.iban = this.companyAdditionalFormGroup.value.ibanInput || "";
	  this.postCreateMemberRequest.faxNumber = this.companyAdditionalFormGroup.value.faxNumber || "";
    this.postCreateMemberRequest.bic = this.companyAdditionalFormGroup.value.bic || "";
    this.postCreateMemberRequest.users.firstNameOne = this.companyAdditionalFormGroup.value.firstNameOne || "";
    this.postCreateMemberRequest.users.lastNameOne = this.companyAdditionalFormGroup.value.lastNameOne || "";
    this.postCreateMemberRequest.users.emailOne = this.companyAdditionalFormGroup.value.emailOne || "";
    this.postCreateMemberRequest.users.firstNameTwo = this.companyAdditionalFormGroup.value.firstNameTwo || "";
    this.postCreateMemberRequest.users.lastNameTwo = this.companyAdditionalFormGroup.value.lastNameTwo || "";
    this.postCreateMemberRequest.users.emailTwo = this.companyAdditionalFormGroup.value.emailTwo || "";

    // formgroup 3
    this.postCreateMemberRequest.invoiceMethod = this.formGroupStepThree.value.invoiceMethod || "";
    this.postCreateMemberRequest.invoiceEmail = this.formGroupStepThree.value.invoiceEmail || "";

    // other address
    this.postCreateMemberRequest.addressToType = this.formGroupStepThree.value.addressToType || "";
    this.postCreateMemberRequest.otherAddress.name = '-';
    this.postCreateMemberRequest.otherAddress.street = this.formGroupStepThree.value.invoiceStreet || "";
    this.postCreateMemberRequest.otherAddress.number = this.formGroupStepThree.value.invoiceNumber || "";
    this.postCreateMemberRequest.otherAddress.addNumber = this.formGroupStepThree.value.invoiceAddNumber || "";
    this.postCreateMemberRequest.otherAddress.postalCode = this.formGroupStepThree.value.invoicePostalCode || "";
    this.postCreateMemberRequest.otherAddress.city = this.formGroupStepThree.value.invoiceCity || "";
    this.postCreateMemberRequest.otherAddress.country = this.formGroupStepThree.value.invoiceCountry || "";

    this.postCreateMemberRequest.department = this.formGroupStepThree.value.department || "";
    this.postCreateMemberRequest.yourReference = this.formGroupStepThree.value.yourReference || "";

    this.postCreateMemberRequest.originalMarketImport = this.formGroupStepThree.value.originalMarketImport || false;
    this.postCreateMemberRequest.originalMarketExport = this.formGroupStepThree.value.originalMarketExport || false;
    this.postCreateMemberRequest.replacementMarketImport = this.formGroupStepThree.value.replacementMarketImport || false;
    this.postCreateMemberRequest.replacementMarketExport = this.formGroupStepThree.value.replacementMarketExport || false;

    Object.keys(this.formGroupStepThree.value.activities).forEach(key => {
      console.log(key);

      if (this.formGroupStepThree.value.activities[key] == true) {
        const memberActivity = {} as memberActivityRequest;

        memberActivity.code = key;
        memberActivity.value = this.formGroupStepThree.value.activities[key];
        this.postCreateMemberRequest.activities.push(memberActivity)
      }
    })

    Object.keys(this.formGroupStepThree.value.imports).forEach(key => {
      console.log(JSON.stringify(this.formGroupStepThree.value.imports[key], null, 4));

      if (this.formGroupStepThree.value.imports[key] > 0) {

        const memberImportFigure = {} as importRequest;

        memberImportFigure.code = key;
        memberImportFigure.value = this.formGroupStepThree.value.imports[key];

        this.postCreateMemberRequest.imports.push(memberImportFigure);
      }
    })

    console.log('formGroupSubmit!! :-)\n\n' + JSON.stringify(this.postCreateMemberRequest, null, 4));

    this.service.createMember(this.postCreateMemberRequest).subscribe(() => {
      this.router.navigate(['/member/register/succes']);
    },
      e => {

        this.router.navigate(['/member/register/failed']);

        console.log(e);
      })
  }


  get isEmailInvoiceMethodSelected(): boolean | null {
    if (!this.formGroupStepThree) {
      return false;
    }

    if (!this.formGroupStepThree.get('invoiceMethod')) {
      return false;
    }

    return (this.formGroupStepThree.get('invoiceMethod').value === 'INVM-EMAIL' || this.formGroupStepThree.get('invoiceMethod').value === 'INVM-PDFINVOICESYSTEM');
  }


  get isAdressToOtherSelected(): boolean | null {
    if (!this.formGroupStepThree) {
      return false;
    }

    if (!this.formGroupStepThree.get('addressToType')) {
      return false;
    }

    return (this.formGroupStepThree.get('addressToType').value === 'ATT-OTHER');
  }

  invoiceMethodRadioChanged() {
    let enabled = this.isEmailInvoiceMethodSelected;

    const invoicingEmailControl = this.formGroupStepThree.get('invoiceEmail');

    if (enabled) {
      this.formGroupStepThree.get('invoiceEmail').enable()
      invoicingEmailControl.setValidators([Validators.required, Validators.email]);
      console.log("enabled");
    }
    else {
      this.formGroupStepThree.get('invoiceEmail').disable()
      invoicingEmailControl.setValidators(null);
      console.log("disabled");
    }

    invoicingEmailControl.updateValueAndValidity();
  }

  adressToRadioChanged() {
    let otherSelected = this.isAdressToOtherSelected;

    const streetControl = this.formGroupStepThree.get('invoiceStreet');
    const numberControl = this.formGroupStepThree.get('invoiceNumber');
    const addNumer = this.formGroupStepThree.get('invoiceAddNumber');
    const postalControl = this.formGroupStepThree.get('invoicePostalCode');
    const cityControl = this.formGroupStepThree.get('invoiceCity');
    const country = this.formGroupStepThree.get('invoiceCountry');


    if (otherSelected) {
      streetControl.enable()
      streetControl.setValidators([Validators.required]);
      numberControl.enable()
      numberControl.setValidators([Validators.required]);
      addNumer.enable()
      postalControl.enable()
      postalControl.setValidators([Validators.required]);
      cityControl.enable()
      cityControl.setValidators([Validators.required]);
      country.enable()
      country.setValidators([Validators.required]);
    } else {
      streetControl.disable()
      streetControl.setValidators(null);
      numberControl.disable()
      numberControl.setValidators(null);
      addNumer.disable()
      postalControl.disable()
      postalControl.setValidators(null);
      cityControl.disable()
      cityControl.setValidators(null);
      country.disable()
      country.setValidators(null);
    }

    streetControl.updateValueAndValidity();
    numberControl.updateValueAndValidity();
    postalControl.updateValueAndValidity();
    cityControl.updateValueAndValidity();
    country.updateValueAndValidity();
  }

  openSnackBar(text: string) {
    this.snackBar.open(text);
  }
}

@Component({
  selector: 'dialog-cancel-registration-dialog',
  templateUrl: 'dialog-cancel-registration.html',
})
export class DialogCancelRegistrationDialog {

  constructor(protected storage: LocalStorageService,protected translate: TranslateService,
    public dialogRef: MatDialogRef<DialogCancelRegistrationDialog>,

    @Inject(MAT_DIALOG_DATA) public cancelRegistrationDialogData: DialogCancelRegistrationData)
    {
      this.translate.use(this.storage.getLanguage().toLowerCase());
    }

  onNoDoNotCancelRegistrationClick(): void {
    this.cancelRegistrationDialogData.confirm = false;
    this.dialogRef.close(this.cancelRegistrationDialogData);
  }

  onYesCancelRegistrationClick(): void {
    this.cancelRegistrationDialogData.confirm = true;
    this.dialogRef.close(this.cancelRegistrationDialogData);
  }
}

@Component({
  selector: 'snack-bar-component',
  templateUrl: '../shared/snack-bar-component.html',
  styles: [`
    .example-pizza-party {
      color: hotpink;
    }
  `],
})
export class DefaultSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}


