import { Component, OnInit } from '@angular/core';
import { UserSignupService } from '../usersignup/usersingup.service';
import { AuthService } from '../authentication/auth.service';
import { Router } from '@angular/router';
import { BaseTranslateComponent } from '../shared/base-translate/base-translate.component';
import { LocalStorageService } from '../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-registration-hub',
  templateUrl: './registration-hub.component.html',
  styleUrls: ['./registration-hub.component.sass']
})
export class RegistrationHubComponent extends BaseTranslateComponent implements OnInit {

  constructor(
    private service: UserSignupService,
    private authService: AuthService,
    private router: Router,
    protected storage: LocalStorageService,protected translate: TranslateService
  ) {
    super(storage,translate);
  }

  getActivationGuid() {
    return this.authService.currentUser.profile.activationguid;
  }

  ngOnInit() {
    this.postInit();
  }

  postInit() {
    let activationGuid = this.getActivationGuid();

    console.log(`activationGuid = ${activationGuid}`)

    this.service.getTicketInfo(activationGuid).subscribe( r => 
      {
        console.log(`before navigate type = ${r.type}`)

        if (r.type === 'RT-MEMBER')   {
          this.router.navigate(['member/register/']);
        } else if (r.type === 'RT-CP')   {
          this.router.navigate(['collectionpoint/register/']);
        }
        else {
          this.router.navigate(['pagenotfound']);
        }

        console.log(`after navigate type = ${r.type}`)
      },
      error => 
      {
        this.router.navigate(['register/allreadyregistered']);

        console.log(`postInit -- getMemberTicketInfo -- error --> ${error}`)
      });
  }

}
