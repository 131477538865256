import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { HttpErrorResponse } from '@angular/common/http';
import { Constants } from '../shared/constants';
import { of, throwError } from 'rxjs';
import { getCollectionPointRegistrationStepOneResponse } from './getCollectionPointRegistrationStepOneResponse';
import { postCollectionPointRegistrationRequest} from './postCollectionPointRegistrationRequest';
import { collectionPointCreatedRequest } from './collectionPointCreatedRequest';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable()
export class CollectionPointRegistrationService {

  constructor(private http: HttpClient) { }

  getCollectionPointStartup(activationGuid: string): Observable<getCollectionPointRegistrationStepOneResponse> {

    console.log(Constants.COLLECTIONPOINT_STARTUP_GET_URL);

    let collectionPointStartupUrl = environment.apiBaseUrl.concat(Constants.COLLECTIONPOINT_STARTUP_GET_URL);

    console.log(collectionPointStartupUrl);

    return this.http.get<getCollectionPointRegistrationStepOneResponse>(collectionPointStartupUrl).map(
      data => {

        console.log(`http get ${collectionPointStartupUrl} - returned ok`);

        return data;
      }).catch(e => {
        console.log(`http get ${collectionPointStartupUrl} - returned error`);

        console.log(e); return Observable.of(getCollectionPointRegistrationStepOneResponse.createError("error while fetching"));
      })
  };

  createCollectionPoint(request: postCollectionPointRegistrationRequest): Observable<boolean> {
    let createCollectionPointUrl = environment.apiBaseUrl.concat(Constants.COLLECTIONPOINT_STARTUP_POST_URL);

    console.log(`http post -- url -- ${createCollectionPointUrl} - returned ok`);

    console.log(JSON.stringify(request, null, 4));

    return this.http.post<collectionPointCreatedRequest>(createCollectionPointUrl, request, httpOptions)
      .map(
        data => {

          console.log(`http post -- url -- ${createCollectionPointUrl} - returned ok ${JSON.stringify(data)} ok`);

          return data.creationLink !== "";
        }).catch(e => {


          console.log(`http post ${createCollectionPointUrl} - returned error`);

          let response: boolean = false;

          console.log(e);

          return throwError(e);
        })
  }

  cancelRegistration(): Observable<boolean> {
    let disableContractorUrl = environment.apiBaseUrl.concat(Constants.SIGNUP_DISABLECONTRACTOR_DELETE_URL);

    return this.http.delete<boolean>(disableContractorUrl, httpOptions)
      .map(
        data => {

          console.log(`http delete -- url -- ${disableContractorUrl} - returned ok`);

          let result: boolean = <boolean>data;

          console.log(`http delete -- result --  ${JSON.stringify(result)} - returned ok`);

          return result;
        }).catch(e => {
          console.log(`http delete ${disableContractorUrl} - returned error`);

          console.log(e);

          return throwError(e);
        })
  }
}