import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from 'src/app/authentication/auth.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollectionpointNotRegisteredGuardService implements CanActivate {
  constructor(private service: AuthService, private router: Router) {

  }

  profile(): any {
    return this.service.currentUser.profile;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.service.isLoggedInObs().flatMap( x => {

          if (this.profile().companyId) {
            console.log(`CompanyId is allready filled in , allready registered`);
            this.router.navigate(['register/allreadyregistered']);
            return  of(false);
          }
          
          if (this.profile().membertickettype !== 'CollectionPoint') {
            console.log(`membertickettype == ${this.profile().membertickettype}, is not collectionpoint`);
            this.router.navigate(['register/allreadyregistered']);
            return  of(false);
          }    
    
          console.log('Not a CollectionPoint yet');
      
          return  of(true);
    })  
  }
}
