import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-test-collectionpoint-charter-form',
  templateUrl: './test-collectionpoint-charter-form.component.html',
  styleUrls: ['./test-collectionpoint-charter-form.component.sass']
})
export class TestCollectionpointCharterFormComponent implements OnInit {
  mainForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.mainForm = this.formBuilder.group({
      charterForm: [],
      autoFillCheckBox :new FormControl(),
      belgianCheckBox :new FormControl()
    });
  }

  fill()
  {
    console.log('fill charter -- start');

    let formData = {
      firstName: "Jan",
      lastName: "Met de pet",
      functionName: null,
      acceptCharter: null
    }

    this.mainForm.patchValue({ charterForm: formData });

    console.log('fill charter -- end');
  }

  get formValue()
  {
    return this.mainForm.get('charterForm').value;
  }

  get form()
  {
    return this.mainForm.get('charterForm');
  }

  ngOnInit() {
    this.onChanges();
  }

  submit() {
    console.log(this.mainForm.value);
  }

  onChanges(): void {
    this.mainForm.get('autoFillCheckBox').valueChanges.subscribe(val => {
        console.log(this.mainForm.value);
    });
  }

}
