import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

  constructor() { }

  ngOnInit(): void {
    localStorage.setItem('rowsNo', "20");
    localStorage.setItem('language', 'NL');
  }

  getLanguage() {
    const languageLocalStorage = localStorage.getItem('language');
    if (!languageLocalStorage || languageLocalStorage === 'undefined') {
      return 'NL';
    } else {
      return languageLocalStorage;
    }
  }

  

  setLanguage(language: string) {
    localStorage.setItem('language', language);
  }

  getGridRowsNo() {
    const rowsLocalStorage = localStorage.getItem('rowsNo');
    if (!rowsLocalStorage || rowsLocalStorage === 'undefined') {
      return '20';
    } else {
      return rowsLocalStorage;
    }
  }

  setGridRowsNo(rowsNo: string) {
    localStorage.setItem('rowsNo', rowsNo);
  }

  getAllowedLocations() {
    var retrievedObject = localStorage.getItem('allowedLocations');
    return JSON.parse(retrievedObject);
  }

  setAllowedLocations(locations) {
    localStorage.setItem('allowedLocations', JSON.stringify(locations));
  }

  clear(){
    localStorage.clear();
  }
}
