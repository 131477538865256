import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { memberActivity } from 'src/app/interface/memberActivity';
import { memberActivities } from 'src/app/shared/constants';

@Component({
  selector: 'app-test-collectionpoint-activities-form',
  templateUrl: './test-collectionpoint-activities-form.component.html',
  styleUrls: ['./test-collectionpoint-activities-form.component.sass']
})
export class TestCollectionpointActivitiesFormComponent implements OnInit {
  mainForm: FormGroup;


  constructor(private formBuilder: FormBuilder) {
    this.mainForm = this.formBuilder.group({
      collectionPointActivitiesForm: [],
      autoFillCheckBox :new FormControl(),
      belgianCheckBox :new FormControl()
    });
  }

  fillCollectionPointActivitiesFormData()
  {
    console.log('collectionPointActivitiesForm -- start');

    this.fillAdditionalCompanyForm(); 

    console.log('collectionPointActivitiesForm -- end');
  }

  get formValue()
  {
    return this.mainForm.get('collectionPointActivitiesForm').value;
  }

  get form()
  {
    return this.mainForm.get('collectionPointActivitiesForm');
  }

  ngOnInit() {
    this.onChanges();
  }

  submit() {
    console.log(this.mainForm.value);
  }

  onChanges(): void {
    this.mainForm.get('autoFillCheckBox').valueChanges.subscribe(val => {
        console.log(this.mainForm.value);
    });
  }

  fillAdditionalCompanyForm() {
    console.log(`fillBelgianCompanyForm`)

    let activityElement = {C4:true};

    let relatedFormData = [];

    let relatedActivityElement1 = {bain:'YES'};
    let relatedActivityElement2 = {baen:'YES'};
    let relatedActivityElement3 = {bais:'NO'};
    let relatedActivityElement4 = {bais:'NO'};

    // relatedFormData.push(relatedActivityElement1);
    // relatedFormData.push(relatedActivityElement2);
    // relatedFormData.push(relatedActivityElement3);
    // relatedFormData.push(relatedActivityElement4);

    let activityFormData = {
      activationId: "836A0F6F-C88A-468F-A024-AA02BE5B4B18",
      addressToType: "ATT-REGISTERED",
      otherAddress: {},
      activities: [],
      additionalActivities: []
    }

    activityFormData.activities.push(activityElement);

    activityFormData.additionalActivities.push(relatedActivityElement1);
    activityFormData.additionalActivities.push(relatedActivityElement2);
    activityFormData.additionalActivities.push(relatedActivityElement3);
    activityFormData.additionalActivities.push(relatedActivityElement4);


    var objectName,objectValue,arrayElement;

    for(arrayElement in activityFormData.activities) 
    {
      for(objectName in arrayElement) {
        objectValue = arrayElement[objectName];

        alert("name : " + objectName + " : value : " + objectValue) ;
      }
    }


   

    this.mainForm.patchValue({ collectionPointActivitiesForm: activityFormData });
  }
}