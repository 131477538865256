import { Component, OnInit, forwardRef, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseTranslateComponent } from '../shared/base-translate/base-translate.component';
import { LocalStorageService } from '../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';


export interface CollectionPointCharterFormValues {
  firstName: string;
  lastName: string;
  functionName: string;
  acceptCharter: boolean;
}

@Component({
  selector: 'collectionpoint-charter-step',
  templateUrl: './collectionpoint-charter-step.component.html',
  styleUrls: ['./collectionpoint-charter-step.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CollectionpointCharterStepComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CollectionpointCharterStepComponent),
      multi: true
    }
  ]
})
export class CollectionpointCharterStepComponent extends BaseTranslateComponent implements ControlValueAccessor,OnDestroy {
  public form: FormGroup;

  subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder,protected storage: LocalStorageService,protected translate: TranslateService) {
    super(storage,translate);

    this.form = this.formBuilder.group({
      firstName :new FormControl(null, [Validators.required]),
      lastName :new FormControl(null, [Validators.required]),
      functionName :new FormControl(null, [Validators.required]),
      acceptCharter :new FormControl(null, [Validators.requiredTrue])
    });
    
    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })); 
  }

  get value(): CollectionPointCharterFormValues {
    return this.form.value;
  }



  set value(value: CollectionPointCharterFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get thisform(): AbstractControl | null {
    return this.form || null;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.form.valid ? null : { profile: { valid: false, }, };
  }
}
