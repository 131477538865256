
import { AppComponent } from './app.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './authentication/auth-guard.service';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { TestComponent } from './test/test.component';
import { AuthCallbackComponent } from './authentication/auth-callback/auth-callback.component';
import { UsersignupComponent } from './usersignup/usersignup.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ActivationGuidNotValidComponent } from './activation-guid-not-valid/activation-guid-not-valid.component';
import { ContractorCreatedFailedComponent } from './usersignup/contractor-created-failed/contractor-created-failed.component';
import { ContractorCreatedSuccesfullComponent } from './usersignup/contractor-created-succesfull/contractor-created-succesfull.component';
import { MemberRegistrationComponent } from './member-registration-wizard/member-registration.component';
import { MemberCreatedSuccesfullComponent } from './member-registration-wizard/member-created-succesfull/member-created-succesfull.component';
import { MemberCreatedFailedComponent } from './member-registration-wizard/member-created-failed/member-created-failed.component';
import { MemberAllreadyRegisteredComponent } from './member-registration-wizard/member-allready-registered/member-allready-registered.component';
import { RegistrationHubComponent } from './registration-hub/registration-hub.component';
import { CollectionpointRegistrationComponent } from './collectionpoint-registration-wizard/collectionpoint-registration.component';
import { TestCompanyAddressFormComponent } from './test/registration-wizard/test-company-adress-form/test-company-address-form.component';
import { TestCompanyAdditionalMemberFormComponent } from './test/registration-wizard/test-company-additional-address-member-form/test-company-additional-address-member-form';
import { TestCompanyAdditionalCollectionPointFormComponent } from './test/registration-wizard/test-company-additional-address-collectionpoint-form/test-company-additional-address-collectionpoint-form';
import { CollectionpointCreatedSuccesfullComponent } from './collectionpoint-registration-wizard/collectionpoint-created-succesfull/collectionpoint-created-succesfull.component';
import { CollectionpointCreatedFailedComponent } from './collectionpoint-registration-wizard/collectionpoint-created-failed/collectionpoint-created-failed.component';
import { TestCollectionpointActivitiesFormComponent } from './test/registration-wizard/test-collectionpoint-activities-form/test-collectionpoint-activities-form.component';
import { TestCollectionpointForecastFormComponent } from './test/registration-wizard/test-collectionpoint-forecast-form/test-collectionpoint-forecast-form.component';
import { TestCollectionpointCharterFormComponent } from './test/registration-wizard/test-collectionpoint-charter-form/test-collectionpoint-charter-form.component';
import { OfflineComponent } from './offline/offline.component';
import { TicketGuardService } from './service/guard/member-ticket-guard.service';
import { MemberNotRegisterdGuardService } from './service/guard/member-not-registered-guard.service';
import { CollectionpointNotRegisteredGuardService } from './service/guard/collectionpoint-not-registered-guard.service';
import { AddressToMethodComponent } from './address-to-method/address-to-method.component';
import { TestAddressLocationComponent } from './test-address-location/test-address-location.component';
import { IbanTestComponent } from './test/iban-test/iban-test.component';

const routes: Routes = [
  //App Layout Routes
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: 'signup/guidnotvalid', component: ActivationGuidNotValidComponent },
      { path: 'signup/succes', component: ContractorCreatedSuccesfullComponent},
      { path: 'signup/failed', component: ContractorCreatedFailedComponent},
      { path: 'signup/:id', component: UsersignupComponent,canActivate: [TicketGuardService]},
      { path: 'home', component: RegistrationHubComponent,canActivate: [AuthGuardService]},
      { path: 'register', component: RegistrationHubComponent,canActivate: [AuthGuardService]},
      { path: 'register/allreadyregistered', component: MemberAllreadyRegisteredComponent,canActivate: [AuthGuardService]},
      { path: 'collectionpoint/register', component: CollectionpointRegistrationComponent,canActivate: [AuthGuardService,CollectionpointNotRegisteredGuardService]},
      { path: 'collectionpoint/register/succes', component: CollectionpointCreatedSuccesfullComponent,canActivate: [AuthGuardService,MemberNotRegisterdGuardService]},
      { path: 'collectionpoint/register/failed', component: CollectionpointCreatedFailedComponent,canActivate: [AuthGuardService]},
      { path: 'member/register', component: MemberRegistrationComponent,canActivate: [AuthGuardService]},
      { path: 'member/register/succes', component: MemberCreatedSuccesfullComponent,canActivate: [AuthGuardService]},
      { path: 'member/register/failed', component: MemberCreatedFailedComponent,canActivate: [AuthGuardService]},
      { path: 'forbidden', component: ForbiddenComponent},
      { path: 'offline', component: OfflineComponent},
      { path: 'test', component: TestComponent},
      { path: 'test/iban', component: IbanTestComponent},
      { path: 'test/subcomp/addressto', component: AddressToMethodComponent},
      { path: 'test/subcomp/addresslocation', component: TestAddressLocationComponent},
      { path: 'test/wizard/address', component: TestCompanyAddressFormComponent},
      { path: 'test/wizard/member/additional', component: TestCompanyAdditionalMemberFormComponent},
      { path: 'test/wizard/cp/additional', component: TestCompanyAdditionalCollectionPointFormComponent},
      { path: 'test/wizard/cp/activities', component: TestCollectionpointActivitiesFormComponent},
      { path: 'test/wizard/cp/forecast', component: TestCollectionpointForecastFormComponent},
      { path: 'test/wizard/cp/charter', component: TestCollectionpointCharterFormComponent},
      { path: '', redirectTo: '/member/register', pathMatch: 'full' },
      { path: 'auth-callback', component: AuthCallbackComponent },
      { path: 'pagenotfound', component: PageNotFoundComponent },
      { path: '**', component: PageNotFoundComponent }
    ]
  },
  //No Layout Routes

//  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
