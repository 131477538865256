import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { LoaderService, LoaderState } from './loader.service';
import { Guid } from '../shared/guid';

@Component({
    selector: 'app-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.sass']
})
export class LoaderComponent implements OnInit, OnDestroy {

    show = false;
    currentLoaders = [];
    private subscription: Subscription;

    constructor(
        private loaderService: LoaderService
    ) { }

    ngOnInit() {
        this.subscription = this.loaderService.loaderState
            .subscribe((state: LoaderState) => {
                const subscriptionId = state.id;
                if (state.show) {
                    this.showLoader(subscriptionId);
                } else {
                    this.hideLoader(subscriptionId);
                }
            });
    }


    private hideLoader(subscriptionId: Guid) {
        const currentLoader = this.currentLoaders.find(x => x.id === subscriptionId);
        if (currentLoader) {
            clearTimeout(currentLoader.timeout);
            const currentLoaderIndex = this.currentLoaders.findIndex(x => x.id === subscriptionId);
            this.currentLoaders.splice(currentLoaderIndex, 1);
        }
        this.show = false
    }

    private showLoader(subscriptionId: Guid) {
        const timeoutMilliseconds = 100;

        this.currentLoaders.push({
            id: subscriptionId,
            timeout: setTimeout(() => {
                this.show = true
            }, timeoutMilliseconds)
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
