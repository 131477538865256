import { LocalStorageService } from '../../service/local-storage.service';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { User } from 'oidc-client';
import { AuthService } from './../auth.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'user-authentication',
  templateUrl: './user-authentication.component.html',
  styleUrls: ['./user-authentication.component.scss']
})
export class UserAuthenticationComponent implements OnInit {
  user: User;
  isLoggedIn: boolean;
  loadedUserSub: any;
  userName: string;

  constructor(private authService: AuthService,private localStorageService :LocalStorageService, private router: Router) { }

   ngOnInit() {
       this.loadedUserSub = this.authService.userLoadedEvent
      .subscribe(user => {
        this.user = user;
        if(user != null){
          this.isLoggedIn = true;
          this.userName = this.authService.getClaims().name;
        }
        else{
          this.isLoggedIn = false;
        }
        this.authService.getClaims();
      });
  }

  logIn(): void {
      this.authService.startAuthentication();
  }

   logOut(): void {

    this.authService.startSignOutUser();

    //this.localStorageService.setGridRowsNo("20");


  }
}
