import { Component, OnInit } from '@angular/core';
import { language } from '../interface/language';
import { AuthService } from '../authentication/auth.service';
import { TestService } from './test.service';
import { CountrySelectComponent } from '../country-select/country-select.component';
import { Constants } from '../shared/constants';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../service/local-storage.service';


@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.sass']
})
export class TestComponent implements OnInit {

  selectedLanguage: string;

  languages: any;

  translanguages: language[] = [
    {value: '0-dutch', viewValue: 'Dutch'},
    {value: '1-french', viewValue: 'French'},
    {value: '2-english', viewValue: 'English'},
    {value: '3-german', viewValue: 'German'},
  ];

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private testService: TestService,
    private storage: LocalStorageService
  ) {
    this.languages = [
      { label: 'const.languages.English', value: 'en' },
      { label: 'const.languages.Dutch', value: 'nl' },
      { label: 'const.languages.French', value: 'fr' }
    ];

  }

  ngOnInit() {
  }

  isLoggedIn(): boolean
  {
    if (!this.authService)
      return false;

    if (!this.authService.currentUser)
      return false;

    if (!this.authService.currentUser.profile)
      return false;

    return true
  }

  setLangs(chosenLang) {
    this.selectedLanguage = chosenLang.toLowerCase();
  }

  changeLang(event: any) {
    console.log(`language changed ui ${this.selectedLanguage.toLowerCase()}`)

    this.translate.use(this.selectedLanguage.toLowerCase());

    this.setLangs(this.selectedLanguage);

    this.storage.setLanguage(this.selectedLanguage);
  }

  get swaggerUrl(): string {
    return environment.apiUrl.concat(Constants.SWAGGER_URL);
  }

  get buildInfo(): string {
    return environment.buildInfo;
  }

  get deployInfo(): string {
    return environment.deployInfo;
  }

  profile(): any {
    if (this.isLoggedIn()) {
      return this.authService.currentUser.profile;
    } else
    {
      return "not logged in"
    }
  }

  testAlwaysTrue(){
    this.testService.testAlwaysTrue().subscribe(
      succes => {
        console.log("getTest - succes ");
      }
      ,
      error => {
        console.log(`getTest -- error -- ${error}`)
      });
  }

  testContractorPolicy(){
    this.testService.testContractorPolicy().subscribe(
      succes => {
        console.log("testContractorPolicy - succes ");
      }
      ,
      error => {
        console.log(`testContractorPolicy -- error -- ${error}`)
      });
  }

  onSubmit() {
    alert('SUCCESS!!');
  }
}
