import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Guid } from '../shared/guid';

export interface LoaderState {
    show: boolean;
    id: Guid;
}

@Injectable()

export class LoaderService {

    private loaderSubject = new Subject<LoaderState>();

    loaderState = this.loaderSubject.asObservable();

    constructor() { }

    show(id: Guid) {
        this.loaderSubject.next(<LoaderState>{ show: true, id: id });
    }

    hide(id: Guid) {
        this.loaderSubject.next(<LoaderState>{ show: false, id: id });
    }
}

