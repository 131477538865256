import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { invoiceMethods } from '../shared/constants';
import { AuthService } from '../authentication/auth.service';
import { CustomErrorStateMatcher } from '../shared/CustomErrorStateMatcher';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { invoiceMethod } from '../interface/invoicingMethod';
import { CollectionPointRegistrationService } from './collectionpoint-registration.service';
import { postCollectionPointRegistrationRequest, collectionPointForecastRequest, collectionPointActivityRequest, collectionPointWasteRequest } from './postCollectionPointRegistrationRequest';
import { getCollectionPointRegistrationStepOneResponse } from './getCollectionPointRegistrationStepOneResponse';
import { DialogCancelRegistrationDialog } from '../member-registration-wizard/member-registration.component';
import { EnvironmentService } from '../service/environment.service';
import { BaseTranslateComponent } from '../shared/base-translate/base-translate.component';
import { LocalStorageService } from '../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-collectionpoint-registration',
  templateUrl: './collectionpoint-registration.component.html',
  styleUrls: ['./collectionpoint-registration.component.sass']
})
export class CollectionpointRegistrationComponent extends BaseTranslateComponent implements OnInit {
  formGroup: FormGroup;

  // localCountries: country[] = countries;
  // // companyForm: FormGroup;

  isAutoFill: boolean = true;

  durationInSeconds = 5;

  urlRegex: string = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  positiveNumberRegex: string = '^[+]?\\d*';

  isLinear: boolean = true;

  secondRowVisible: boolean = false;

  public userId: Number;

  selectedInvoiceMethod: string;

  errorMatcher = new CustomErrorStateMatcher();

  formSubmitted: boolean;

  postCreateCollectionPointRequest: postCollectionPointRegistrationRequest;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private service: CollectionPointRegistrationService,
    private environmentService: EnvironmentService,
    protected storage: LocalStorageService,
    protected translate: TranslateService
  ) {
    super(storage,translate);

    this.formGroup = this.formBuilder.group({
      formArray: this.formBuilder.array([
        this.formBuilder.group({ companyForm: [] }),
        this.formBuilder.group({ companyAdditionalForm: []}),
        this.formBuilder.group({ activitiesForm: []}),
        this.formBuilder.group({ forecastForm: []}),
        this.formBuilder.group({ charterForm: []})
      ])
    });
  }


  localInvoiceMethods: invoiceMethod[] = invoiceMethods;

  get showDebugInfo(): boolean {
    return this.environmentService.showDebugInfo;
  }

  ngOnInit() {
    this.postInit();
  }

  get formArray(): AbstractControl | null {
    return this.formGroup.get('formArray') || null;
  }

  get formGroupStepOne(): AbstractControl | null {
    return this.formArray && this.formArray.get([0]) || null;
  }

  get formGroupStepTwo(): AbstractControl | null {
    return this.formArray && this.formArray.get([1]) || null;
  }

  get formGroupStepThree(): AbstractControl | null {
    return this.formArray && this.formArray.get([2]) || null;
  }

  get formGroupStepFour(): AbstractControl | null {
    return this.formArray && this.formArray.get([3]) || null;
  }

  get formGroupStepFive(): AbstractControl | null {
    return this.formArray && this.formArray.get([4]) || null;
  }


  get formGroupStepOneIsValid(): boolean {
    return this.formGroupStepOne && false
  }

  openDialogCancelRegistration(): void {
    console.log('clicked cancel');

    const dialogRef = this.dialog.open(DialogCancelRegistrationDialog, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      console.log(JSON.stringify(result));

      if (result && result.confirm) {
        this.service.cancelRegistration().subscribe(() => {
          this.openSnackBar('Cancel collectionpoint registration succeeded!');
          setTimeout(()=>{ this.logOut(); }, 2000);
        },
          e => {
            this.openSnackBar('Cancel collectionpoint registration failed!');

            console.log(e);
          })
      }
    });


  }

  profile(): any {
    return this.authService.currentUser.profile;
  }

  logOut(): void {
    this.storage.clear();
    this.authService.startSignOutUser();
  }

  fillBelgianCompanyForm(response: getCollectionPointRegistrationStepOneResponse) {
    console.log(`fillBelgianCompanyForm -- ${response.vatNumber}`)

    if (!response) {
      return;
    }

    if (!response.address) {
      return;
    }

    let belgianCompanyFormData = {
      activationId: this.authService.currentUser.profile.activationguid,
      vatNumber: response.vatNumber,
      name: response.companyName,
      companyType: response.companyTypeDescription,
      street: response.address.street,
      number: response.address.number,
      addNumber: response.address.addNumber,
      postalCode: response.address.postalCode,
      city: response.address.city,
      country: response.address.country
    }

    this.formGroupStepOne.patchValue({ companyForm: belgianCompanyFormData });
  }

  fillNonBelgianCompanyForm(response: getCollectionPointRegistrationStepOneResponse) {
    console.log(`fillNonBelgianCompanyForm -- ${response.vatNumber}`)

    this.isAutoFill = false;

    let nonBelgianCompanyFormData = {
      activationId: this.authService.currentUser.profile.activationguid,
      vatNumber: response.vatNumber,
      name: "",
      companyType: "",
      street: "",
      number: "",
      addNumber: "",
      postalCode: "",
      city: "",
      country: `CNT-${response.vatNumber.substr(0, 2)}`
    }

    this.formGroupStepOne.patchValue({ companyForm: nonBelgianCompanyFormData });
  }

  fillCharterForm() {
    let formData = {
      firstName: this.authService.currentUser.profile.given_name,
      lastName: this.authService.currentUser.profile.family_name,
      functionName: null,
      acceptCharter: null
    }

    this.formGroupStepFive.patchValue({ charterForm: formData });
  }

  fillAdditionCompany(response: getCollectionPointRegistrationStepOneResponse) {
    let belgianAdditionalCompanyFormData = {
      exploitationAddressType: "AET-DEFAULT",
      exploitationAddress: {},
      preferredLanguage: response.preferredLanguage,
      telephoneNumber: null,
      faxNumber: null,
      webSite: null,
      ibanInput: null,
      bic: null,
      firstNameOne: null,
      lastNameOne: null,
      emailOne: null,
      firstNameTwo: null,
      lastNameTwo: null,
      emailTwo: null
    };

    this.formGroupStepTwo.patchValue({ companyAdditionalForm: belgianAdditionalCompanyFormData });
  }

  fillForcastWithTestData() {

    let forecastFormData = [];

    let forecastElement = {vt1:6};

    forecastFormData.push(forecastElement);

    this.formGroupStepFive.patchValue({forecasts: forecastFormData})
  }

  fillActivityWithTestData() {

    this.formGroupStepThree.patchValue({addressToType: 'ATT-OTHER'})

    let activityFormData= [];

    let activityElement = {C4:true};

    activityFormData.push(activityElement);

    this.formGroupStepThree.patchValue({activities: activityFormData})

    let relatedFormData = [];

    let relatedActivityElement1 = {bain:'YES'};
    let relatedActivityElement2 = {baen:'YES'};
    let relatedActivityElement3 = {bais:'NO'};
    let relatedActivityElement4 = {bais:'NO'};

    relatedFormData.push(relatedActivityElement1);
    relatedFormData.push(relatedActivityElement2);
    relatedFormData.push(relatedActivityElement3);
    relatedFormData.push(relatedActivityElement4);

    this.formGroupStepThree.patchValue({relatedActivities: relatedFormData})
  }


  fillCharterWithTestData() {
    let charterFormData = {
      firstName: 'juppeldepup',
      lastName: 'lastnamejup',
      functionName: "functionname",
      acceptCharter:true

    }

    this.formGroupStepFive.patchValue({charterForm: charterFormData})
  }

  fillAdditionCompanyWithTestData() {
    let belgianAdditionalCompanyFormData = {
      activationId: this.authService.currentUser.profile.activationguid,
      telephoneNumber: 1234567,
      faxNumber: 999,
      webSite: "www.standaard.be",
      iban: 123,
      bic: 456,
      firstNameOne: null,
      lastNameOne: null,
      emailOne: null,
      firstNameTwo: null,
      lastNameTwo: null,
      emailTwo: null
    };

    this.formGroupStepTwo.patchValue({ companyAdditionalForm: belgianAdditionalCompanyFormData });
  }

  postInit() {
    const companyId = new Number(this.profile().companyId);

    console.log(`companyId = ${this.profile().companyId}`);

    console.log(`companyId = ${companyId}`);

    if (companyId > 0 && false) {
      this.router.navigate(['/collectionpoint/register/allreadyregistered']);

      return;
    }

    this.service.getCollectionPointStartup(this.profile().activationguid).subscribe(response => {
      if(response.error) {
        console.log(`postInit -- getCollectionPointStartup -- error --> ${response.error}`)

        this.openSnackBar(response.error)

        return;
      }

      console.log(response.address.country)

      if (response.vatNumber.startsWith("BE")) {
        this.fillBelgianCompanyForm(response);
      } else {
        this.fillNonBelgianCompanyForm(response);
      }

      this.fillAdditionCompany(response);

      this.fillCharterForm();

      // this.fillActivitesForm();

      this.formGroupStepOne.markAsUntouched();

      this.formGroupStepTwo.markAsUntouched();

      this.formGroupStepThree.markAsUntouched();
    }
      ,
      error => {
        console.log(`postInit -- getMemberRegistrationStepOne -- error --> ${error}`)

        this.openSnackBar(error)
      });
  }

  onGroupOneSubmit() {
    console.log(this.formGroupStepOne.value);
  }

  onGroupTwoSubmit() {
    console.log(this.formGroupStepTwo.value);
  }

  get companyFormGroup(): AbstractControl | null {
    return this.formArray && this.formArray.get([0]) && this.formArray.get([0]).get('companyForm') || null;
  }

  get companyAdditionalFormGroup(): AbstractControl | null {
    return this.formArray && this.formArray.get([1]) && this.formArray.get([1]).get('companyAdditionalForm') || null;
  }

  get activitiesFormGroup(): AbstractControl | null {
    return this.formArray && this.formArray.get([2]) && this.formArray.get([2]).get('activitiesForm') || null;
  }
  get forecastFormGroup(): AbstractControl | null {
    return this.formArray && this.formArray.get([3]) && this.formArray.get([3]).get('forecastForm') || null;
  }

  get charterFormGroup(): AbstractControl | null {
    return this.formArray && this.formArray.get([4]) && this.formArray.get([4]).get('charterForm') || null;
  }

  formGroupFillInTestData() {

    this.fillAdditionCompanyWithTestData();

    this.fillCharterWithTestData();

    this.fillForcastWithTestData();

    this.fillActivityWithTestData();
  }


  formGroupSubmit() {
    this.formSubmitted = true;

    this.postCreateCollectionPointRequest = new postCollectionPointRegistrationRequest();

    // formgroup 1 - company
    this.postCreateCollectionPointRequest.companyAddress.name = this.companyFormGroup.value.name || "-";
    this.postCreateCollectionPointRequest.companyAddress.street = this.companyFormGroup.value.street || "";
    this.postCreateCollectionPointRequest.companyAddress.number = this.companyFormGroup.value.number || "";
    this.postCreateCollectionPointRequest.companyAddress.addNumber = this.companyFormGroup.value.addNumber || "";
    this.postCreateCollectionPointRequest.companyAddress.postalCode = this.companyFormGroup.value.postalCode || "";
    this.postCreateCollectionPointRequest.companyAddress.city = this.companyFormGroup.value.city || "";
    this.postCreateCollectionPointRequest.companyAddress.country = this.companyFormGroup.value.country || "";

    // formgroup 2 - additional company
    this.postCreateCollectionPointRequest.preferredLanguage = this.companyAdditionalFormGroup.value.preferredLanguage || "";
    this.postCreateCollectionPointRequest.telephoneNumber = this.companyAdditionalFormGroup.value.telephoneNumber || "";
    this.postCreateCollectionPointRequest.webSite = this.companyAdditionalFormGroup.value.webSite || "";
    this.postCreateCollectionPointRequest.faxNumber = this.companyAdditionalFormGroup.value.faxNumber || "";
    this.postCreateCollectionPointRequest.iban = this.companyAdditionalFormGroup.value.ibanInput || "";
    this.postCreateCollectionPointRequest.bic = this.companyAdditionalFormGroup.value.bic || "";
    this.postCreateCollectionPointRequest.users.firstNameOne = this.companyAdditionalFormGroup.value.firstNameOne || "";
    this.postCreateCollectionPointRequest.users.lastNameOne = this.companyAdditionalFormGroup.value.lastNameOne || "";
    this.postCreateCollectionPointRequest.users.emailOne = this.companyAdditionalFormGroup.value.emailOne || "";
    this.postCreateCollectionPointRequest.users.firstNameTwo = this.companyAdditionalFormGroup.value.firstNameTwo || "";
    this.postCreateCollectionPointRequest.users.lastNameTwo = this.companyAdditionalFormGroup.value.lastNameTwo || "";
    this.postCreateCollectionPointRequest.users.emailTwo = this.companyAdditionalFormGroup.value.emailTwo || "";

    // formgroup 3
    //this.postCreateCollectionPointRequest.activities = this.activitiesFormGroup.value.activities;
    this.postCreateCollectionPointRequest.locationAddressType = this.activitiesFormGroup.value.addressToType || 'ATT-REGISTERED';

    if (this.activitiesFormGroup.value.addressToType === 'ATT-OTHER') {
      this.postCreateCollectionPointRequest.locationAddress.name = this.activitiesFormGroup.value.otherAddress.name || "";
      this.postCreateCollectionPointRequest.locationAddress.street = this.activitiesFormGroup.value.otherAddress.street || "";
      this.postCreateCollectionPointRequest.locationAddress.number = this.activitiesFormGroup.value.otherAddress.number || "";
      this.postCreateCollectionPointRequest.locationAddress.addNumber = this.activitiesFormGroup.value.otherAddress.addNumber || "";
      this.postCreateCollectionPointRequest.locationAddress.postalCode = this.activitiesFormGroup.value.otherAddress.postalCode || "";
      this.postCreateCollectionPointRequest.locationAddress.city = this.activitiesFormGroup.value.otherAddress.city || "";
      this.postCreateCollectionPointRequest.locationAddress.country = this.activitiesFormGroup.value.otherAddress.country || "";
    }

    Object.keys(this.activitiesFormGroup.value.activities).forEach(key => {
      console.log(JSON.stringify(this.activitiesFormGroup.value.activities[key], null, 4));

      if (this.activitiesFormGroup.value.activities[key]) {

        const collectionPointActivityRequest = {} as collectionPointActivityRequest;

        collectionPointActivityRequest.code = key;
        collectionPointActivityRequest.value = this.activitiesFormGroup.value.activities[key];

        this.postCreateCollectionPointRequest.activities.push(collectionPointActivityRequest);
      }
    })

    Object.keys(this.activitiesFormGroup.value.relatedActivities).forEach(key => {
      console.log(JSON.stringify(this.activitiesFormGroup.value.relatedActivities[key], null, 4));

      if (this.activitiesFormGroup.value.relatedActivities[key]) {

        const collectionPointRelatedActivityRequest = {} as collectionPointActivityRequest;

        collectionPointRelatedActivityRequest.code = key;
        collectionPointRelatedActivityRequest.value = this.activitiesFormGroup.value.relatedActivities[key] == 'YES';

        this.postCreateCollectionPointRequest.relatedActivities.push(collectionPointRelatedActivityRequest);
      }
    })

    Object.keys(this.forecastFormGroup.value.forecasts).forEach(key => {
      console.log(JSON.stringify(this.forecastFormGroup.value.forecasts[key], null, 4));

      if (this.forecastFormGroup.value.forecasts[key] > 0) {

        const collectionPointForecastRequest = {} as collectionPointForecastRequest;

        collectionPointForecastRequest.code = key;
        collectionPointForecastRequest.value = this.forecastFormGroup.value.forecasts[key] ;

        this.postCreateCollectionPointRequest.forecast.push(collectionPointForecastRequest);
      }
    })

    // waste -- loop all values in form and push them into the request
    Object.keys(this.forecastFormGroup.value.waste).forEach(key => {
      console.log(JSON.stringify(this.forecastFormGroup.value.waste[key], null, 4));

      if (this.forecastFormGroup.value.waste[key]) {

        const cpWasteRequest = {} as collectionPointWasteRequest;

        cpWasteRequest.code = key;
        cpWasteRequest.value = this.forecastFormGroup.value.waste[key] == 'YES';

        this.postCreateCollectionPointRequest.waste.push(cpWasteRequest);
      }
    })

    this.postCreateCollectionPointRequest.charter = this.charterFormGroup.value;

    console.log('formGroupSubmit!! :-)\n\n' + JSON.stringify(this.postCreateCollectionPointRequest, null, 4));

    this.service.createCollectionPoint(this.postCreateCollectionPointRequest).subscribe((result) => {
      if (result == true) {
        console.log("createCollectionPoint - true");
        this.router.navigate(['/collectionpoint/register/succes']);
      }
      else
      {
        console.log("createCollectionPoint - false");
        this.router.navigate(['/collectionpoint/register/failed']);
      }
    },
      e => {
        console.log(e);
        this.router.navigate(['/collectionpoint/register/failed']);
      })
  }

  showSecondRow() {
    this.secondRowVisible = true;
  }

  get addUserDisabled(): boolean {
    return this.secondRowVisible;
  }

  get hideSecondRow(): boolean {
    return !this.secondRowVisible;
  }

  removeSecondRow() {
    this.secondRowVisible = false;

    const firstNameControl = this.formGroupStepTwo.get('firstNameTwo');
    const lastNameControl = this.formGroupStepTwo.get('lastNameTwo');
    const emailControl = this.formGroupStepTwo.get('emailTwo');

    firstNameControl.setValue(null);
    lastNameControl.setValue(null);
    emailControl.setValue(null)

    firstNameControl.setValidators(null);
    lastNameControl.setValidators(null);
    emailControl.setValidators(null);

    firstNameControl.updateValueAndValidity();
    lastNameControl.updateValueAndValidity();
    emailControl.updateValueAndValidity()
  }

  setCompanyFieldsEnabled() {
    // todo something
  }

  setUserRowOneValidation() {
    const firstNameControl = this.formGroupStepTwo.get('firstNameOne');
    const lastNameControl = this.formGroupStepTwo.get('lastNameOne');
    const emailControl = this.formGroupStepTwo.get('emailOne');

    if (this.userRowOneHasData) {
      firstNameControl.setValidators([Validators.required]);
      lastNameControl.setValidators([Validators.required]);
      emailControl.setValidators([Validators.required, Validators.email]);

    } else {
      firstNameControl.setValidators(null);
      lastNameControl.setValidators(null);
      emailControl.setValidators(null);
    }

    firstNameControl.updateValueAndValidity();
    lastNameControl.updateValueAndValidity();
    emailControl.updateValueAndValidity()
  }

  setUserRowTwoValidation() {
    const firstNameControl = this.formGroupStepTwo.get('firstNameTwo');
    const lastNameControl = this.formGroupStepTwo.get('lastNameTwo');
    const emailControl = this.formGroupStepTwo.get('emailTwo');

    if (this.userRowTwoHasData && this.secondRowVisible) {
      firstNameControl.setValidators([Validators.required]);
      lastNameControl.setValidators([Validators.required]);
      emailControl.setValidators([Validators.required, Validators.email]);

    } else {
      firstNameControl.setValidators(null);
      lastNameControl.setValidators(null);
      emailControl.setValidators(null);
    }

    firstNameControl.updateValueAndValidity();
    lastNameControl.updateValueAndValidity();
    emailControl.updateValueAndValidity()
  }

  get userRowOneHasData(): boolean {
    return this.formGroupStepTwo.get('firstNameOne').value ||
      this.formGroupStepTwo.get('lastNameOne').value ||
      this.formGroupStepTwo.get('emailOne').value;
  }

  get userRowTwoHasData(): boolean {
    return this.formGroupStepTwo.get('firstNameTwo').value ||
      this.formGroupStepTwo.get('lastNameTwo').value ||
      this.formGroupStepTwo.get('emailTwo').value;
  }

  get isEmailInvoiceMethodSelected(): boolean | null {
    if (!this.formGroupStepThree) {
      return false;
    }

    if (!this.formGroupStepThree.get('invoiceMethod')) {
      return false;
    }

    return (this.formGroupStepThree.get('invoiceMethod').value === 'INVM-EMAIL');
  }


  get isAdressToOtherSelected(): boolean | null {
    if (!this.formGroupStepThree) {
      return false;
    }

    if (!this.formGroupStepThree.get('adressToType')) {
      return false;
    }

    return (this.formGroupStepThree.get('adressToType').value === 'ATT-OTHER');
  }

  invoiceMethodRadioChanged() {
    let enabled = this.isEmailInvoiceMethodSelected;
    const invoicingEmailControl = this.formGroupStepThree.get('invoiceEmail');

    if (enabled) {
      this.formGroupStepThree.get('invoiceEmail').enable()
      invoicingEmailControl.setValidators([Validators.required, Validators.email]);
      console.log("enabled");
    }
    else {
      this.formGroupStepThree.get('invoiceEmail').disable()
      invoicingEmailControl.setValidators(null);
      console.log("disabled");
    }

    invoicingEmailControl.updateValueAndValidity();
  }

  adressToRadioChanged() {
    let otherSelected = this.isAdressToOtherSelected;

    const streetControl = this.formGroupStepThree.get('invoiceStreet');
    const numberControl = this.formGroupStepThree.get('invoiceNumber');
    const addNumer = this.formGroupStepThree.get('invoiceAddNumber');
    const postalControl = this.formGroupStepThree.get('invoicePostalCode');
    const cityControl = this.formGroupStepThree.get('invoiceCity');
    const country = this.formGroupStepThree.get('invoiceCountry');


    if (otherSelected) {
      streetControl.enable()
      streetControl.setValidators([Validators.required]);
      numberControl.enable()
      numberControl.setValidators([Validators.required]);
      addNumer.enable()
      postalControl.enable()
      postalControl.setValidators([Validators.required]);
      cityControl.enable()
      cityControl.setValidators([Validators.required]);
      country.enable()
      country.setValidators([Validators.required]);
    } else {
      streetControl.disable()
      streetControl.setValidators(null);
      numberControl.disable()
      numberControl.setValidators(null);
      addNumer.disable()
      postalControl.disable()
      postalControl.setValidators(null);
      cityControl.disable()
      cityControl.setValidators(null);
      country.disable()
      country.setValidators(null);
    }

    streetControl.updateValueAndValidity();
    numberControl.updateValueAndValidity();
    postalControl.updateValueAndValidity();
    cityControl.updateValueAndValidity();
    country.updateValueAndValidity();
  }

  openSnackBar(text: string) {
    this.snackBar.open(text);
  }
}


