import { FormGroup } from '@angular/forms';

export function AtLeastOneOfFourSelected(firstControlName: string, secondControlName: string, thirdControlName: string, fouredControlName: string) {
  return (formGroup: FormGroup) => {
      
    const firstControl = formGroup.get(firstControlName);
    const secondControl = formGroup.get(secondControlName);
    const thirdControl = formGroup.get(thirdControlName);
    const fouredControl = formGroup.get(fouredControlName);

    // return null if controls haven't initialised yet
    if (!firstControl || !secondControl || !thirdControl || !fouredControl) {
      return null;
    }
    if (firstControl.errors && !firstControl.errors.atLeastOneSelected) {
      return null;
    }
    // return null if another validator has already found an error on the matchingControl
    if (secondControl.errors && !secondControl.errors.atLeastOneSelected) {
      return null;
    }
    if (thirdControl.errors && !thirdControl.errors.atLeastOneSelected) {
      return null;
    }
    if (fouredControl.errors && !fouredControl.errors.atLeastOneSelected) {
      return null;
    }
    // set error when no control is selected ( at least one radio button )
    if (!firstControl.value &&
      !secondControl.value &&
      !thirdControl.value &&
      !fouredControl.value) {
      fouredControl.setErrors({ atLeastOneSelected: true });
    }
    else {
      fouredControl.setErrors(null);
    }
  };
}
