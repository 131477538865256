import { Component } from '@angular/core';
import { BaseTranslateComponent } from './shared/base-translate/base-translate.component';
import { LocalStorageService } from './service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseTranslateComponent {

  constructor(protected storage: LocalStorageService, protected translate: TranslateService) {
    super(storage, translate);

  }
}