import { Injectable } from '@angular/core';
import { FormElementBase } from '../elements/form-element-base';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ForeCastElement } from '../elements/forecast-element';
import { Constants } from '../shared/constants';
import { WasteElement } from '../elements/WasteElement';
import { LocalStorageService } from '../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';


export const wasteTypes: string[] = [
  'wa1'
]

export const foreCastFormElements: FormElementBase<any>[] = [
  new ForeCastElement({ key: 'PT-AG',required: true, label: 'Car' }),
  new ForeCastElement({ key: 'PT-E1',required: true, label: 'Vans' }),
  new ForeCastElement({ key: 'PT-E2',required: true, label: 'Motorcycle' }),
  new ForeCastElement({ key: 'PT-INA',required: true, label: 'Truck' }),
  new ForeCastElement({ key: 'PT-INP',required: true, label: 'Earthmovers' }),
  new ForeCastElement({ key: 'PT-PL',required: true, label: 'Argricultural vehicles' }),
  new ForeCastElement({ key: 'PT-TO',required: true, label: 'Industrial vehicles (air tyres)' })
];

export const wasteFormElements: FormElementBase<any>[] = [
  new WasteElement({ key: 'wa1',required: true, label: 'const.waste-questions.wa1' })
];

@Injectable({
  providedIn: 'root'
})
export class CollectionPointForeCastStepService {

  constructor(private formBuilder: FormBuilder,protected storage: LocalStorageService,protected translate: TranslateService) { 

  }

  public getForeCastElements() : FormElementBase<any>[] {
    return foreCastFormElements;
  }

  public getWasteElements() : FormElementBase<any>[] {
    return wasteFormElements
  }
  public toForeCastFormGroup(elements: FormElementBase<any>[]) {
    let group: any = {};

    elements.forEach(element => 
      { 
        group[element.key] = new FormControl(element.value || '',[Validators.pattern(Constants.Regex_positiveNumber)])                                     
      }
    );

    return this.formBuilder.group(group);
  }

  public toWasteFormGroup(elements: FormElementBase<any>[]) {
      let group: any = {};
  
      elements.forEach(element => 
        { 
          group[element.key] = new FormControl(element.value || '', [Validators.required])                                     
        }
      );

    return this.formBuilder.group(group);
  }
}
