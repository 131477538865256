import {Component, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { ActivationGuidNotValidComponent } from 'src/app/activation-guid-not-valid/activation-guid-not-valid.component';
import {CompanyAdditionalStepComponent} from "../../../company-additional-step/company-additional-step.component";

@Component({
  selector: 'test-company-additional-address-collectionpoint-form',
  templateUrl: './test-company-additional-address-collectionpoint-form.html',
  styleUrls: ['./test-company-additional-address-collectionpoint-form.sass']
})
export class TestCompanyAdditionalCollectionPointFormComponent implements OnInit {
  mainForm: FormGroup;
  formattedMessage: string;
  isAutoFill: boolean;

  @ViewChild(CompanyAdditionalStepComponent, {static: true}) companyAdditionalStepComponent: CompanyAdditionalStepComponent;

  constructor(private formBuilder: FormBuilder) {
    this.mainForm = this.formBuilder.group({
      additionalCompanyForm: [],
      checkboxShowAddress: new FormControl(),
      autoFillCheckBox: new FormControl(),
      belgianCheckBox: new FormControl()
    });
  }

  fillAdditionalCompanyData() {
    console.log('fillAdditionalCompanyData -- start');

    this.fillAdditionalCompanyForm();

    console.log('fillAdditionalCompanyData -- end');
  }

  get additionalCompanyFormValue() {
    return this.mainForm.get('additionalCompanyForm').value;
  }

  get companyForm() {
    return this.mainForm.get('additionalCompanyForm');
  }

  ngOnInit() {

  }

  submit() {
    console.log(this.mainForm.value);
  }

  onChanges(): void {
    this.mainForm.get('autoFillCheckBox').valueChanges.subscribe(val => {
      console.log(this.mainForm.value);

      this.isAutoFill = val;
    });
  }

  fillAdditionalCompanyForm() {
    console.log(`fillBelgianCompanyForm`)

    let additionalCompanyFormData = {
      exploitationAddressType: "AET-DEFAULT",
      exploitationAddress: null,
      preferredLanguage: '1-DUTCH',
      telephoneNumber: "050510927",
      faxNumber: "050510926",
      webSite: "http://www.standaard.be",
      ibanInput: {
        ibanInputField: "123"
      },
      bic: "BBBBBBBB",
      firstNameOne: "Filip",
      lastNameOne: "Wtterwulghe",
      emailOne: "filip.wtterwulghe@gmail.com",
      firstNameTwo: null,
      lastNameTwo: null,
      emailTwo: null
    }

    this.mainForm.patchValue({ additionalCompanyForm: additionalCompanyFormData });
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors = (control instanceof FormGroup || control instanceof FormArray)
        ? this.getAllErrors(control)
        : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
  }
}
