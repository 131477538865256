import { Component, OnInit, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormElementBase } from '../elements/form-element-base';
import { CollectionPointForeCastStepService } from './collectionpoint-forecast-step.service';
import { AtLeastOneHasAValue } from '../shared/function/AtLeastOneSelectedMultiple';
import { BaseTranslateComponent } from '../shared/base-translate/base-translate.component';
import { LocalStorageService } from '../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

export interface GenericFormValues {
  activationId: string;
}

@Component({
  selector: 'collectionpoint-forecast-step',
  templateUrl: './collectionpoint-forecast-step.component.html',
  styleUrls: ['./collectionpoint-forecast-step.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CollectionpointForecastStepComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CollectionpointForecastStepComponent),
      multi: true
    }
  ]
})
export class CollectionpointForecastStepComponent extends BaseTranslateComponent implements ControlValueAccessor,OnDestroy {
  
  public form: FormGroup;

  public foreCastsFormGroup: FormGroup;

  public wasteFormGroup: FormGroup;

  forecastElements: FormElementBase<any>[] = [];

  wasteElements: FormElementBase<any>[] = [];

  subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder,
    private cfService: CollectionPointForeCastStepService,
    protected storage: LocalStorageService,
    protected translate: TranslateService) {

      super(storage,translate);

      this.forecastElements = this.cfService.getForeCastElements();

      this.wasteElements = this.cfService.getWasteElements();

      this.foreCastsFormGroup = this.cfService.toForeCastFormGroup(this.forecastElements);

      this.wasteFormGroup = this.cfService.toWasteFormGroup(this.wasteElements);
  
      // this.form = this.formBuilder.group({forecasts: this.foreCastsFormGroup}, {validator: [AtLeastOneHasAValue('forecasts')]});
      this.form = this.formBuilder.group(
        {forecasts: this.foreCastsFormGroup,
         waste: this.wasteFormGroup
        },
        {  
          validator: [ AtLeastOneHasAValue('forecasts') ]
        });


    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })); 
  }

  get value(): GenericFormValues {
    return this.form.value;
  }

  set value(value: GenericFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.form.valid ? null : { profile: { valid: false, }, };
  }

}
