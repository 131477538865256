import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { Constants } from '../shared/constants';
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators";
import { environment } from '../../environments/environment';


export interface IIbanValidationResult {
  valid: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class IbanService {

  private _noAuthHttpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this._noAuthHttpClient = new HttpClient(handler);
  }

  validate(ibanToValidate: string): Observable<IIbanValidationResult> {
    const postObject = { action: "checkEmailExists" };

    let ibanValidationUrl = environment.apiBaseUrl.concat(Constants.IBAN_VALIDATION_URL).concat(ibanToValidate);

     return this._noAuthHttpClient.get<IIbanValidationResult>(ibanValidationUrl).map(
      data => {

        console.log(`http get ${ibanValidationUrl} - returned ok`);

        let result: IIbanValidationResult = {valid: true};

        return result;
      }).catch(e => {
        console.log(`http get ${ibanValidationUrl} - returned error`);

        console.log(e);

        let result: IIbanValidationResult = {valid: false};

        return Observable.of(result);
      })
  }
}
