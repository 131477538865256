import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Constants } from '../shared/constants';
import { throwError } from 'rxjs';
import { getMemberRegistrationStepOneResponse } from './getMemberRegistrationStepOneResponse';
import { postMemberRegistrationRequest } from './postMemberRegistrationRequest';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable()
export class MemberRegistrationService {

  constructor(private http: HttpClient) { }

  getMemberStartup(activationGuid: string): Observable<getMemberRegistrationStepOneResponse> {

    console.log(Constants.MEMBER_STARTUP_GET_URL);

    let memberStartupUrl = environment.apiBaseUrl.concat(Constants.MEMBER_STARTUP_GET_URL);

    console.log(memberStartupUrl);

    return this.http.get<getMemberRegistrationStepOneResponse>(memberStartupUrl).map(
      data => {

        console.log(`http get ${memberStartupUrl} - returned ok`);

        return data;
      }).catch(e => {
        console.log(`http get ${memberStartupUrl} - returned error`);

        console.log(e); 
        
        return Observable.of(getMemberRegistrationStepOneResponse.createError('error while fetching'));
      })
  };

  createMember(request: postMemberRegistrationRequest): Observable<boolean> {
    let createMemberUrl = environment.apiBaseUrl.concat(Constants.MEMBER_STARTUP_POST_URL);

    console.log(`http post -- url -- ${createMemberUrl} - returned ok`);

    console.log(JSON.stringify(request, null, 4));

    return this.http.post<boolean>(createMemberUrl, request, httpOptions)
      .map(
        data => {

          console.log(`http post -- url -- ${createMemberUrl} - returned ok`);

          let result: boolean = <boolean>data;

          console.log(`http post -- result --  ${JSON.stringify(result)} - returned ok`);

          return result;
        }).catch(e => {
          console.log(`http post ${createMemberUrl} - returned error`);

          let response: boolean;

          console.log(e);
          
          return throwError(e);
        })
  }

  cancelRegistration(): Observable<boolean> {
    let disableContractorUrl = environment.apiBaseUrl.concat(Constants.SIGNUP_DISABLECONTRACTOR_DELETE_URL);

    return this.http.delete<boolean>(disableContractorUrl, httpOptions)
      .map(
        data => {

          console.log(`http delete -- url -- ${disableContractorUrl} - returned ok`);

          let result: boolean = <boolean>data;

          console.log(`http delete -- result --  ${JSON.stringify(result)} - returned ok`);

          return result;
        }).catch(e => {
          console.log(`http delete ${disableContractorUrl} - returned error`);

          console.log(e);

          return throwError(e);
        })
  }
}