export class postCollectionPointRegistrationRequest {

  public activationId: string = '';
  public telephoneNumber: string = '';
  public preferredLanguage: string = '';
  public faxNumber: string = '';
  public webSite: string = '';
  public iban: string = '';
  public bic: string = '';

  public charter: any;

  public companyAddress: adressRequest = new adressRequest()
  public locationAddressType: string
  public locationAddress: adressRequest = new adressRequest()
  public users: usersRequest = new usersRequest()
  public activities: Array<any> = new Array
  public relatedActivities: Array<any> = new Array
  public forecast: Array<any> = new Array
  public waste: Array<any> = new Array


  constructor() {
  }
}

export class usersRequest {
  constructor(
    public firstNameOne: string = '',
    public lastNameOne: string = '',
    public emailOne: string = '',
    public firstNameTwo: string = '',
    public lastNameTwo: string = '',
    public emailTwo: string = '') {

  }
}

export class adressRequest {
  /**
   *
   */
  constructor(
    public name: string = '',
    public street: string = '',
    public number: string = '',
    public addNumber: string = '',
    public postalCode: string = '',
    public city: string = '',
    public country: string = '') {
  }
}

export interface collectionPointActivityRequest {
  code: string;
  value: boolean;
}

export interface collectionPointForecastRequest {
  code: string;
  value: number;
}

export interface collectionPointWasteRequest {
  code: string;
  value: boolean;
}
