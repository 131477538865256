import { Router } from '@angular/router';
import { AuthService } from './../auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'silent-renew',
  templateUrl: './silent-renew.component.html'
})
export class SilentRenewComponent implements OnInit {
    constructor(private authService: AuthService, private router: Router) { }

    ngOnInit() {
        this.authService.silentRenewCallback().then(result => {
        });       
    }
}