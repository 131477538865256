import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FormElementBase } from '../elements/form-element-base';
import { RelatedActivitiesElement } from '../elements/related-activities-element';
import { ActivitiesElement } from "../elements/activities-element";
import { LocalStorageService } from '../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

export const collectionPointActivitiesFormElements: FormElementBase<any>[] = [
  new ActivitiesElement({ key: 'C4', label: 'const.collectionpoint-activities.C4' }),
  new ActivitiesElement({ key: 'C14', label: 'const.collectionpoint-activities.C14' }),
  new ActivitiesElement({ key: 'C16', label: 'const.collectionpoint-activities.C16' }),
  // new ActivitiesElement({ key: 'M5', label: 'const.collectionpoint-activities.M5' }),
  new ActivitiesElement({ key: 'C13', label: 'const.collectionpoint-activities.C13' }),
  new ActivitiesElement({ key: 'C5', label: 'const.collectionpoint-activities.C5' }),
  // new ActivitiesElement({ key: 'M2', label: 'const.collectionpoint-activities.M2' }),
  new ActivitiesElement({ key: 'C6', label: 'const.collectionpoint-activities.C6' }),
  new ActivitiesElement({ key: 'C8', label: 'const.collectionpoint-activities.C8' }),
  new ActivitiesElement({ key: 'C12', label: 'const.collectionpoint-activities.C12' }),
  // new ActivitiesElement({ key: 'C10', label: 'const.collectionpoint-activities.C10' }),
  new ActivitiesElement({ key: 'C15', label: 'const.collectionpoint-activities.C15' }),
  // new ActivitiesElement({ key: 'M1', label: 'const.collectionpoint-activities.M1' }),cyr
  new ActivitiesElement({ key: 'C11', label: 'const.collectionpoint-activities.C11' }),
  // new ActivitiesElement({ key: 'M6', label: 'const.collectionpoint-activities.M6' }),
  new ActivitiesElement({ key: 'C7', label: 'const.collectionpoint-activities.C7' }),
  // new ActivitiesElement({ key: 'M8', label: 'const.collectionpoint-activities.M8' }),
  new ActivitiesElement({ key: 'C3', label: 'const.collectionpoint-activities.C3' }),
  new ActivitiesElement({ key: 'M3', label: 'const.collectionpoint-activities.M3' }),
  new ActivitiesElement({ key: 'C1', label: 'const.collectionpoint-activities.C1' }),
  new ActivitiesElement({ key: 'C2', label: 'const.collectionpoint-activities.C2' })
];

export const collectionPointBelgianActivitiesFormElements: FormElementBase<any>[] = [
  new RelatedActivitiesElement({ key: 'bain',required: true, label: 'const.collectionpoint-related-activities.bain' }),
  new RelatedActivitiesElement({ key: 'baen',required: true, label: 'const.collectionpoint-related-activities.baen' }),
  new RelatedActivitiesElement({ key: 'bais',required: true, label: 'const.collectionpoint-related-activities.bais' }),
  new RelatedActivitiesElement({ key: 'baes',required: true, label: 'const.collectionpoint-related-activities.baes' }),
];


@Injectable({
  providedIn: 'root'
})
export class CollectionpointActivitiesStepService {
  constructor(private formBuilder: FormBuilder,
    protected storage: LocalStorageService,
    protected translate: TranslateService) { 

    }

  public getCollectionPointActivities() : FormElementBase<any>[] {
    return collectionPointActivitiesFormElements;
  }

  public getCollectionPointRelatedActivities() : FormElementBase<any>[] {
    return collectionPointBelgianActivitiesFormElements;
  }

  public toFormGroup(elements: FormElementBase<any>[]) {
    let group: any = {};

    elements.forEach(element => {
      group[element.key] = element.required ? new FormControl(element.value || '', Validators.required)
                                              : new FormControl(element.value || '');
    });

    return this.formBuilder.group(group);
  }
}
