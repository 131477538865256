import { MonitoringService } from '../../../shared/monitoring.service';
import { Component, ReflectiveInjector } from '@angular/core'; 

@Component({ 
    selector: 'base',
    templateUrl: 'base.component.html'
}) 
export class BaseComponent { 

	private myMonitoringService: MonitoringService; 

	constructor() { 
		const injector = ReflectiveInjector.resolveAndCreate([ 
			MonitoringService 
		]); 
		this.myMonitoringService = injector.get(MonitoringService); 
		this.logNavigation(); 
	} 

	private logNavigation() { 
		this.myMonitoringService.logPageView(); 
	} 
} 