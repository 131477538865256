import { Component, OnInit, forwardRef, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';
import { country } from '../interface/country';
import { countries, languages, companyTypes } from '../shared/constants';
import { language } from '../interface/language';
import { companyType } from '../interface/companyType';
import { BaseTranslateComponent } from '../shared/base-translate/base-translate.component';
import { LocalStorageService } from '../service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

export interface CompanyFormValues {
  activationId: string;
  vatNumber: string;
  name: string;
  companyType: string;
  street: string;
  number: string;
  addNumber: string;
  postalCode: string;
  city: string;
  country: string;
}

@Component({
  selector: 'company-address',
  templateUrl: './company.address.component.html',
  styleUrls: ['./company.address.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanyAddressComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CompanyAddressComponent),
      multi: true
    }
  ]
})
export class CompanyAddressComponent extends BaseTranslateComponent implements ControlValueAccessor,OnDestroy {

  @Input()
  set autoFill(value: boolean){
    this._autoFill = value;

    if (!this._autoFill) {
      this.setCompanyFieldsEnabled();
    }
    else
    {
      this.setCompanyFieldsDisabled();
    }
    
  }

  get autoFill(): boolean {
    return this._autoFill;
  }

  private _autoFill: boolean;

  public form: FormGroup
  // describes what the return value of the form control will look like
  subscriptions: Subscription[] = [];

  localCountries: country[] = countries;

  localCompanyTypes: companyType[] = companyTypes;

  constructor(
    private formBuilder: FormBuilder,
    protected storage: LocalStorageService,
    protected translate: TranslateService

  ) {
    super(storage,translate);

    this.form = this.formBuilder.group({
      activationId: new FormControl({ value: null, hidden: true }, [Validators.required]),
      vatNumber: new FormControl({ value: null, disabled: true }, [Validators.required]),
      name: new FormControl({ value: null, disabled: this.autoFill }, [Validators.required]),
      companyType: new FormControl({ value: null, disabled: this.autoFill }, [Validators.required]),
      street: new FormControl({ value: null, disabled: this.autoFill }, [Validators.required]),
      number: new FormControl({ value: null, disabled: this.autoFill }, [Validators.required]),
      addNumber: new FormControl({ value: null, disabled: this.autoFill }),
      postalCode: new FormControl({ value: null, disabled: this.autoFill }, [Validators.required]),
      city: new FormControl({ value: null, disabled: this.autoFill }, [Validators.required]),
      country: new FormControl({ value: 'CNT-BE', disabled: this.autoFill })
    });



    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })); 
  }

  
  get value(): CompanyFormValues {
    return this.form.value;
  }

  set value(value: CompanyFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.form.valid ? null : { profile: { valid: false, }, };
  }


  get formGroupStepOne(): AbstractControl | null {
    return this.form || null;
  }

  private setCompanyFieldsEnabled() {
    const nameControl = this.formGroupStepOne.get('name');
    const companyTypeControl = this.formGroupStepOne.get('companyType');
    const streetControl = this.formGroupStepOne.get('street');
    const numberControl = this.formGroupStepOne.get('number');
    const addNumberControl = this.formGroupStepOne.get('addNumber');
    const postalCodeControl = this.formGroupStepOne.get('postalCode');
    const cityControl = this.formGroupStepOne.get('city');
    const countryControl = this.formGroupStepOne.get('country');

    nameControl.enable();
    companyTypeControl.enable();
    streetControl.enable();
    numberControl.enable();
    addNumberControl.enable();
    postalCodeControl.enable();
    cityControl.enable();
    countryControl.enable();
  }

  private setCompanyFieldsDisabled() {
    const nameControl = this.formGroupStepOne.get('name');
    const companyTypeControl = this.formGroupStepOne.get('companyType');
    const streetControl = this.formGroupStepOne.get('street');
    const numberControl = this.formGroupStepOne.get('number');
    const addNumberControl = this.formGroupStepOne.get('addNumber');
    const postalCodeControl = this.formGroupStepOne.get('postalCode');
    const cityControl = this.formGroupStepOne.get('city');
    const countryControl = this.formGroupStepOne.get('country');

    nameControl.disable();
    companyTypeControl.disable();
    streetControl.disable();
    numberControl.disable();
    addNumberControl.disable();
    postalCodeControl.disable();
    cityControl.disable();
    countryControl.disable();
  }


}
