import { FormElementBase } from './form-element-base';
export class ActivitiesElement extends FormElementBase<string> {
  controlType = 'checkbox';
  type: string;
  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}

