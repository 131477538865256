import { Component, OnInit, Input, forwardRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormArray } from '@angular/forms';
import { country } from '../interface/country';
import { countries } from '../shared/constants';
import { TranslateService } from '@ngx-translate/core';
import { CustomErrorStateMatcher } from '../shared/CustomErrorStateMatcher';
import { Subscription } from 'rxjs';
import { $ } from 'protractor';

export interface AddressFormValues {
  name: string,
  street: string,
  number: string,
  addNumber: string,
  postalCode: string,
  city: string,
  country: string
}

@Component({
  selector: 'address-to-method',
  templateUrl: './address-to-method.component.html',
  styleUrls: ['./address-to-method.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressToMethodComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressToMethodComponent),
      multi: true
    }
  ]
})
export class AddressToMethodComponent implements  ControlValueAccessor,OnInit {

  public addressformGroup: FormGroup; 

  subscriptions: Subscription[] = [];

  _enabled: boolean = false;

  _showName: boolean = true;

  _showDebug: boolean = false;

  localCountries: country[] = countries;

  errorMatcher = new CustomErrorStateMatcher();
  
  @Input() set isactive(value: boolean) {
    this._enabled = value;

    this.setAddressControlEnabled();
 }

 @Input() set showName(value: boolean) {
  this._showName = value;
}


@Input() set showDebug(value: boolean) {
  this._showDebug = value;
}

  constructor(private fb: FormBuilder,protected translate: TranslateService)  {
    this.addressformGroup = this.fb.group({
      name: new FormControl({ value: null, disabled: true }),
      street: new FormControl({ value: null, disabled: true }),
      number: new FormControl({ value: null, disabled: true }),
      addNumber: new FormControl({ value: null, disabled: true }),
      postalCode: new FormControl({ value: null, disabled: true }),
      city: new FormControl({ value: null, disabled: true }),
      country: new FormControl({ value: 'CNT-BE', disabled: true }),
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.addressformGroup.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      }));
   }

  ngOnInit() {
    this._enabled = false;

    this.setAddressControlEnabled();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get value(): AddressFormValues {
    return this.addressformGroup.value;
  }

  onChange: any = () => {
    console.log('onChange');
  };

  onTouched: any = () => {
    console.log('onTouched');
  };

  set value(value: AddressFormValues) {

    if (Object.entries(value).length === 0 && value.constructor === Object) {
      console.log('value is null');
    } else {
      console.log(`set value  = ${JSON.stringify(value)} `)
      this.addressformGroup.setValue(value);
    }

    this.onChange(value);

    this.onTouched();
  }

  setAddressControlEnabled() {
    console.log("set address control =" + this._enabled)

    const nameControl = this.addressformGroup.get('name');
    const streetControl = this.addressformGroup.get('street');
    const numberControl = this.addressformGroup.get('number');
    const addNumer = this.addressformGroup.get('addNumber');
    const postalControl = this.addressformGroup.get('postalCode');
    const cityControl = this.addressformGroup.get('city');
    const country = this.addressformGroup.get('country');

    if (this._enabled) {

      if(this._showName) {
        nameControl.enable();
        nameControl.setValidators([Validators.required]);
      }
      else
      {
        nameControl.disable();
        nameControl.setValidators(null);
      }

      streetControl.enable()
      streetControl.setValidators([Validators.required]);
      numberControl.enable()
      numberControl.setValidators([Validators.required]);
      addNumer.enable()
      postalControl.enable()
      postalControl.setValidators([Validators.required]);
      cityControl.enable()
      cityControl.setValidators([Validators.required]);
      country.enable()
      country.setValidators([Validators.required]);
    } else {
      nameControl.disable()
      nameControl.setValidators(null);
      streetControl.disable()
      streetControl.setValidators(null);
      numberControl.disable()
      numberControl.setValidators(null);
      addNumer.disable()
      postalControl.disable()
      postalControl.setValidators(null);
      cityControl.disable()
      cityControl.setValidators(null);
      country.disable()
      country.setValidators(null);
    }

    nameControl.updateValueAndValidity();
    streetControl.updateValueAndValidity();
    numberControl.updateValueAndValidity();
    postalControl.updateValueAndValidity();
    cityControl.updateValueAndValidity();
    country.updateValueAndValidity();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.addressformGroup.reset();
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    console.log('is valid ' + this.addressformGroup.valid);

    console.log(this.addressformGroup.errors)

    if (this._enabled) {
      return this.addressformGroup.valid ? null : { profile: { valid: false, }, };
    } else {
      return null
    }    
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors = (control instanceof FormGroup || control instanceof FormArray)
        ? this.getAllErrors(control)
        : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
  }

}
