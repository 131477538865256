import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {
  FormControl,
  ValidationErrors,
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
  Validator, AsyncValidatorFn
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of, Subscription, timer} from 'rxjs';
import {debounceTime, delay, first, map, switchMap} from 'rxjs/operators';
import {LocalStorageService} from '../service/local-storage.service';
import {IbanService} from '../service/iban.service';
import {BaseTranslateComponent} from '../shared/base-translate/base-translate.component';
import {distinctUntilChanged} from 'rxjs-compat/operator/distinctUntilChanged';

export interface IbanFormValues {
  ibanInput: string;
}

@Component({
  selector: 'iban-input',
  templateUrl: './iban.component.html',
  styleUrls: ['./iban.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IbanComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IbanComponent),
      multi: true
    }
  ]
})
export class IbanComponent extends BaseTranslateComponent implements ControlValueAccessor, Validator {

  ibanForm: FormGroup;

  subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder, private ibanService: IbanService, protected storage: LocalStorageService,
              protected translate: TranslateService) {

    super(storage, translate);


  }

  createGroup() {
    this.ibanForm = this.fb.group({
      ibanInput: ["", [Validators.required], [this.validateIbanAsync()]]
    });

    // this.subscriptions.push(
    //   // any time the inner form changes update the parent of any change
    //   this.ibanForm.valueChanges.subscribe(value => {
    //     this.onChange(value);
    //     this.onTouched();
    //   }));

    return this.ibanForm;
  }

  // ngOnInit() {
  // }
  //
  // ngOnDestroy() {
  //   this.subscriptions.forEach(s => s.unsubscribe());
  // }

  // briteVerifyValidator(service: Service) {
  //   return (control: AbstractControl) => {
  //     if (!control.valueChanges) {
  //       return of(null);
  //     } else {
  //       return control.valueChanges.pipe(
  //         debounceTime(1000),
  //         distinctUntilChanged(),
  //         switchMap(value => service.getData(value)),
  //         map(data => {
  //           return data.status === 'invalid' ? { invalid: true } : null;
  //         })
  //       ).pipe(first())
  //     }
  //   }
  // }

  validateIbanSimpleAsync(
    control: AbstractControl
  ): Observable<{ [key: string]: any } | null> {
    if (control.value === null || control.value.length === 0) {
      // when empty it's invalid
      console.log("it's empty --> not valid");
      return of(({invalid: true}))
    } else if (control.value >= 5) {
      console.log("it's not empty --> > 5 valid");
      return of(null)
    } else {
      // when not empty is valid
      console.log("it's not empty --> < 5 invalid");
      console.log("validation control");
      return of(({invalid: true}))
    }
  }

  validateIbanAsyncWrong(
    control: AbstractControl
  ): Observable<{ [key: string]: any } | null> {
    console.log("iban validation start");
    if (control.value === null || control.value.length === 0) {
      console.log("iban validation - empty --> valid");
      return of(null);
    } else if (control.value == 5) {
      console.log("it's 5 --> > 5 valid");
      return of(null)
    } else if (control.value == 6) {
      console.log("it's 6 --> > 6 with delay valid");
      return of(null).pipe(delay(1000))
    } else {
      console.log("iban validation - service is called --> ?");
      return this.ibanService.validate(control.value).take(1).map(res => {
        //Do what with response
        console.log('validationresult = ' + res);

        if (!res.valid) {
          console.log("it's invalid --> returning value invalid: true ");
          return of({invalid: true});
        }

        console.log("it's valid --> returning null");
        return of(null);
      });
    }
  }

  takenUsernames = [
    'hello',
    'world',
    'username'
    // ...
  ];

  checkIfUsernameExists(username: string): Observable<boolean> {
    return of(this.takenUsernames.includes(username)).pipe(delay(1000));
  }

  usernameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkIfUsernameExists(control.value).pipe(
        map(res => {
          console.log(res);
          // if res is true, username exists, return true
          return res ? { usernameExists: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }


  validateIbanAsync(): AsyncValidatorFn {

    return (control: AbstractControl): Observable<ValidationErrors | null> => {

      console.log("iban validation start");
      if (control.value === null || control.value.length === 0) {
        console.log("iban validation - empty --> valid");
        return of(null);
      } else if (control.value == 5) {
        console.log("it's 5 --> > 5 valid");
        return of(null)
      } else if (control.value == 6) {
        console.log("it's 6 --> > 6 with delay valid");
        return of(null).pipe(delay(1000))
      } else {
        console.log("iban validation - service is called --> ?");
        return this.ibanService.validate(control.value).take(1).map(res => {
          //Do what with response
          console.log('validationresult = ' + res);

          if (!res.valid) {
            console.log("it's invalid --> returning value invalid: true ");
            return {invalid: true};
          }

          console.log("it's valid --> returning null");
          return null;
        });
      }
    }
  }

  serviceCall() {
    return timer(1000).pipe(
      switchMap(() => {
        return this.ibanService.validate("123").pipe(
          map(res => {
            //Do what with response
            console.log("iban validation - service is called --> ?");

            console.log(res);

            if (!res.valid) {
              console.log("it's invalid --> returning value invalid: true ");
              return ({invalid: true});
            }

            console.log("it's valid --> returning null");
            return null;
          })
        );
      })
    ).pipe(first());
  }


  validateIban(
    control: AbstractControl
  ): Observable<{ [key: string]: any } | null> {
    if (control.value === null || control.value.length === 0) {
      return of(null);
    } else {
      return timer(1000).pipe(
        switchMap(() => {
          return this.ibanService.validate(control.value).pipe(
            map(res => {
              //Do what with response
              console.log("validation control");

              console.log(res);

              if (!res.valid) {

                console.log("it's invalid");

                return ({invalid: true})
              }
            })
          );
        })
      ).pipe(first());
    }
  }

  validateIban2(
    control: AbstractControl
  ): Observable<{ [key: string]: any } | null> {
    if (control.value === null || control.value.length === 0) {
      return of(null);
    } else {
      return this.ibanService.validate(control.value).pipe(
        debounceTime(500),
        map(res => {
          //Do what with response
          console.log("validation control");

          console.log(res);

          if (!res.valid) {
            console.log("it's invalid");

            return ({invalid: true})
          }
        })
      );
    }
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null && this.ibanForm) {
      this.ibanForm.reset();
    }
  };

  registerOnChange(fn) {
    this.onChange = fn;
  };

  registerOnTouched(fn) {
    this.onTouched = fn;
  };

  setDisabledState?(isDisabled: boolean): void {
  }

  onChange: any = (x) => {

    let a = 1;

  };

  onTouched: any = (x) => {

    let b = 1;

  };


  get value(): IbanFormValues {
    return this.ibanForm.value;
  }

  set value(value: IbanFormValues) {
    this.ibanForm.setValue(value);

    this.onChange(value);

    this.onTouched();
  }

  validate(_: FormControl) {
    if(this.ibanForm)
    {
      console.log(`this.ibanForm.valid --> ${this.ibanForm.valid}`);

      return !this.ibanForm.valid ? {profile: {valid: false,},} : null ;
    }
    else
    {
      return null;
    }

  }
}
