import { FormGroup } from '@angular/forms';
export function AtLeastOneHasAValue(formGroupName: string) {
  return (formGroup: FormGroup) => {
    
    let formGroupToCheck = formGroup.get(formGroupName);

    // return null if another validator has already found an error on the matchingControl
    if (formGroupToCheck.errors && !formGroupToCheck.errors.atLeastOneSelectedMultiple) {
      return null;
    }

    let count = 0;

    if (formGroupToCheck instanceof FormGroup) {
      const controls = (formGroupToCheck as FormGroup).controls
      for (const name in controls) {
         if (controls[name].enabled && controls[name].value && Number(controls[name].value) > 0) {

            count++
         }
      }
    }

    if(count == 0) {
        formGroupToCheck.setErrors({atLeastOneHasAValue: true });
    } else {
        formGroupToCheck.setErrors(null);
    }
  };  
}


