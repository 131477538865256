import { HttpClient, HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { UserSignup } from './usersingup';
import { Constants } from '../shared/constants';
import { of } from 'rxjs';
import { getTicketInfoResponse } from './getMemberTicketInfo';
import { catchError, retry } from 'rxjs/operators';
import { postCreateContractorRequest } from './postCreateContractorRequest';
import { postCreateContractorResponse } from './postCreateContractorResponse';

const httpOptions = { headers: new HttpHeaders({'Content-Type':  'application/json'}) };

@Injectable()
export class UserSignupService {

  private noAuthHttpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.noAuthHttpClient = new HttpClient(handler);
  }

  canStartFlow(activationGuid: string,workflow: string): Observable<boolean> {

    var memberTicketActivationUrl: string;

    if (workflow == 'MEMBER_REGISTRATION') {
      memberTicketActivationUrl = environment.apiBaseUrl.concat(Constants.TICKET_SIGNUP_GET_URL).concat(activationGuid);
    } else if (workflow == 'COLLECTIONPOINT_REGISTRATION') {
      memberTicketActivationUrl = environment.apiBaseUrl.concat(Constants.CAN_START_NEW_CONTRACTOR_WORKFLOW_URL).concat(activationGuid);
    } else if (workflow == 'CONTRACTOR_REGISTRATION') {
      memberTicketActivationUrl = environment.apiBaseUrl.concat(Constants.CAN_START_NEW_CONTRACTOR_WORKFLOW_URL).concat(activationGuid);
    } else {
      return of(false)
    }

    
    console.log(`canStartFlowUrl = ${memberTicketActivationUrl}`);

    return this.noAuthHttpClient.get(memberTicketActivationUrl).map(
        data => {

          console.log(`http get ${memberTicketActivationUrl} - returned ok`);

          return true;
        }).catch(e => 
          { 
            console.log(`http get ${memberTicketActivationUrl} - returned error`);
            console.log(e); return Observable.of(false); 
          })
    };

    getTicketInfo(activationGuid: string): Observable<getTicketInfoResponse> {

      let memberTicketSignupUrl = environment.apiBaseUrl
      .concat(Constants.TICKET_SIGNUP_GET_URL)
      .concat(activationGuid);
  
      console.log(`memberTicketSignupUrl = ${memberTicketSignupUrl}`);
  
      return this.noAuthHttpClient.get<getTicketInfoResponse>(memberTicketSignupUrl).map(
          data => {
  
            console.log(`http get -- url -- ${memberTicketSignupUrl} - returned ok`);

            console.log(`http get -- result --  ${JSON.stringify(data)} - returned ok`);
  
            return data;
          }).catch(e => 
            { 
              console.log(`http get ${memberTicketSignupUrl} - returned error`);

              let response :getTicketInfoResponse;  

              response.found = false;

              console.log(e); return Observable.of(response); 
            })
      };

createContractorUser(request: postCreateContractorRequest): Observable<postCreateContractorResponse> {
  let createContractorUrl = environment.apiBaseUrl.concat(Constants.SIGNUP_CREATECONTRACTOR_POST_URL);

  return this.noAuthHttpClient.post<postCreateContractorResponse>(createContractorUrl,request,httpOptions)
  .map(
    data => {

      console.log(`http post -- url -- ${createContractorUrl} - returned ok`);

      let result: postCreateContractorResponse = <postCreateContractorResponse>data;

      console.log(`http post -- result --  ${JSON.stringify(result)} - returned ok`);

      return result;
    }).catch(e => 
      { 
        console.log(`http post ${createContractorUrl} - returned error`);

        let response :postCreateContractorResponse;  

        console.log(e); 
        
        return Observable.of(response); 
      })
}

      createMemberTicketInfo(activationGuid: string): Observable<getTicketInfoResponse> {

        let memberTicketSignupUrl = environment.apiBaseUrl
        .concat(Constants.TICKET_SIGNUP_GET_URL)
        .concat(activationGuid);
    
        console.log(`memberTicketSignupUrl = ${memberTicketSignupUrl}`);
    
        return this.noAuthHttpClient.get(memberTicketSignupUrl).map(
            data => {
    
              console.log(`http get -- url -- ${memberTicketSignupUrl} - returned ok`);
  
              let result: getTicketInfoResponse = <getTicketInfoResponse>data ;
  
              console.log(`http get -- result --  ${JSON.stringify(result)} - returned ok`);
    
              return result;
            }).catch(e => 
              { 
                console.log(`http get ${memberTicketSignupUrl} - returned error`);
  
                let response :getTicketInfoResponse;  
  
                response.found = false;
  
                console.log(e); return Observable.of(response); 
              })
        };
  }