import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {AsyncValidatorFn, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {debounceTime, distinctUntilChanged, first, map, switchMap} from "rxjs/operators";
import {IbanService, IIbanValidationResult} from "./iban.service";
import {of} from "rxjs";
import {IbanTestComponent} from "../test/iban-test/iban-test.component";

@Injectable({
  providedIn: 'root'
})
export class ComponentFactoryService {

  constructor(private fb: FormBuilder,private ibanService: IbanService ) { }

  createGroupTest() : FormGroup {
    return this.fb.group({
      ibanInput: ["", [Validators.required], [this.validateIbanAsync()]]
    });
  }

  createIbanFormGroup(): FormGroup {
    return this.fb.group({
      ibanInputField: ['123', [Validators.required], [this.validateIbanAsync()]]
    });
  }

  validateIbanAsync(): AsyncValidatorFn {
    return control => {
      if (control && control.valueChanges) {
        console.log("control && control.valueChanges --> not null");

        return control.valueChanges
          .pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(value => this.ibanService.validate(value)),
            map((iIbanValidationResult: IIbanValidationResult) => (iIbanValidationResult.valid ? null : {'iban-invalid-format': true})),
            first());
      }
      else
      {
        console.log("control && control.valueChanges --> null");

        return of(null);
      }
    }
  }; // important to make observable finite

}
