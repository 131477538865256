import { RouterModule } from '@angular/router';
import { AuthService } from './auth.service';
import { AuthGuardService } from './auth-guard.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { SilentRenewComponent } from './silent-renew/silent-renew.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UserAuthenticationComponent } from './user-authentication/user-authentication.component';

@NgModule({
  declarations: [
    UserAuthenticationComponent,
    SilentRenewComponent,
    AuthCallbackComponent
  ],
  providers: [
    AuthGuardService,
    AuthService,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    RouterModule.forChild(
        [
            {
                path: 'auth-callback',
                component: AuthCallbackComponent
            }
        ]
      ),
  ],
  exports: [
    UserAuthenticationComponent,
    SilentRenewComponent,
    AuthCallbackComponent
  ]
})
export class AuthenticationModule { }
